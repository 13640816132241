import { useContext } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { message } from 'antd';
import {
    CategorySetId,
    ResultEnvelope,
    UpdateCategorySetInput,
    UpdateCategorySetOutput
} from '@olxeu-monetization/product-catalog-api-client';
import { Loader } from '../../components/Loader';
import {
    CategoriesDataContext,
    MarketLanguagesDataContext
} from '../../context';
import { useProductCatalogService } from '../../helpers';
import { Messages } from '../../intl';
import {
    CategorySetFormLayout,
    CategorySetFormPayload
} from '../../layouts/CategorySetFormLayout';
import { buildLink, MarketId, Path, PathParams } from '../../routing';
import { CompletionParams, errorNotification, usePromise } from '../../utils';
import { buildBreadcrumbsRoutes as buildUpstreamBreadcrumbsRoutes } from '../CategorySetListPage';

type Params = PathParams[Path.CategorySetEdit];

const buildBreadcrumbsRoutes = (
    categorySetId: CategorySetId,
    marketId: MarketId,
    intl: IntlShape
) => {
    return [
        ...buildUpstreamBreadcrumbsRoutes(marketId, intl),
        {
            path: buildLink(Path.CategorySetEdit, {
                id: categorySetId,
                marketId
            }),
            breadcrumbName: intl.formatMessage(
                Messages['common.label.edit-category-set']
            )
        }
    ];
};

export const CategorySetEditPage = () => {
    const intl = useIntl();
    const categoriesData = useContext(CategoriesDataContext);
    const marketLanguagesData = useContext(MarketLanguagesDataContext);
    const params = useParams<Params>();
    const navigate = useNavigate();

    const { getCategorySet, updateCategorySet } = useProductCatalogService();

    const categorySetResult = usePromise({
        variables: {
            id: params.id
        },
        promiseBuilder: async (variables) => {
            return getCategorySet(variables);
        }
    });

    const handleCategorySetUpdateComplete = (
        completionParams: CompletionParams<
            unknown,
            ResultEnvelope<UpdateCategorySetOutput, unknown>
        >
    ) => {
        if (completionParams.error) {
            void errorNotification({
                error: completionParams.error,
                message: intl.formatMessage(
                    Messages['common.error-message.category-set-edit']
                )
            });

            return;
        }

        void message.success(
            intl.formatMessage(
                Messages['common.success-message.category-set-edit']
            )
        );

        const detailsLink = buildLink(Path.CategorySetDetails, {
            marketId: params.marketId,
            id: completionParams.data?.data?.id as string
        });

        navigate(detailsLink);
    };

    const categorySetUpdate = usePromise({
        variables: undefined as unknown as UpdateCategorySetInput,
        promiseBuilder: async (variables) => {
            return updateCategorySet(variables);
        },
        disableAutostart: true,
        onComplete: handleCategorySetUpdateComplete
    });

    const categoriesLoading = categoriesData.loading && !categoriesData.data;
    const marketLanguagesLoading =
        marketLanguagesData.loading && !marketLanguagesData.data;
    const categorySetLoading =
        categorySetResult.loading && !categorySetResult.data;

    const loading =
        categorySetLoading || marketLanguagesLoading || categoriesLoading;

    if (loading) {
        return <Loader type="center" />;
    }

    const categories = categoriesData.data;
    const marketLanguages = marketLanguagesData.data?.current;
    const categorySet = categorySetResult.data?.data;

    if (!categorySet || !marketLanguages || !categories) {
        return null;
    }

    const breadcrumbRoutes = buildBreadcrumbsRoutes(
        params.id,
        params.marketId,
        intl
    );

    const handleSubmit = (categorySet: CategorySetFormPayload) => {
        const { type, ...rest } = categorySet;

        const input = {
            ...rest,
            id: params.id
        };

        void categorySetUpdate.execute(input);
    };

    return (
        <CategorySetFormLayout
            title={intl.formatMessage(
                Messages['common.label.edit-category-set']
            )}
            categorySet={categorySet}
            categories={categories}
            breadcrumbRoutes={breadcrumbRoutes}
            marketLanguages={marketLanguages}
            submitting={categorySetUpdate.loading}
            onSubmit={handleSubmit}
        />
    );
};
