import { useContext, useMemo, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, message, Result } from 'antd';
import {
    CancelOfferChangeInput,
    OfferChangeId,
    OfferChangeStatus,
    ProductId,
    PublishOfferChangeInput,
    RemoveOfferChangeProductsInput,
    UpdateOfferChangeInput
} from '@olxeu-monetization/product-catalog-api-client';
import { Loader } from '../../components/Loader';
import { Filters } from '../../components/OfferChangeSections';
import { FeaturesDataContext, ProductTypesDataContext } from '../../context';
import { getCategorySetNames, useProductCatalogService } from '../../helpers';
import { Messages } from '../../intl';
import { DraftOfferChangeDetailsLayout } from '../../layouts/DraftOfferChangeDetailsLayout';
import { FinishedOfferChangeDetailsLayout } from '../../layouts/FinishedOfferChangeDetailsLayout';
import {
    buildLink,
    MarketId,
    Path,
    PathParams,
    QueryParamsSchemas
} from '../../routing';
import {
    CompletionParams,
    errorNotification,
    usePromise,
    useQueryParams
} from '../../utils';
import { buildBreadcrumbsRoutes as buildUpstreamBreadcrumbsRoutes } from '../OfferChangeListPage';

const PaginationInitialPageIndex = 0;
const PaginationDefaultPageSize = 20;

const QueryParamsSchema = QueryParamsSchemas[Path.OfferChangeDetails];

type Params = PathParams[Path.OfferChangeDetails];

export const buildBreadcrumbsRoutes = (
    offerChangeId: OfferChangeId,
    marketId: MarketId,
    intl: IntlShape
) => {
    return [
        ...buildUpstreamBreadcrumbsRoutes(marketId, intl),
        {
            path: buildLink(Path.OfferChangeDetails, {
                id: offerChangeId,
                marketId
            }),
            breadcrumbName: intl.formatMessage(
                Messages['common.label.offer-change']
            )
        }
    ];
};

export const OfferChangeDetailsPage = () => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const featuresData = useContext(FeaturesDataContext);
    const productTypesData = useContext(ProductTypesDataContext);
    const params = useParams<Params>();
    const [nameEditEnabled, setNameEditEnabled] = useState(false);

    const navigate = useNavigate();

    const listLink = buildLink(Path.OfferChangeList, {
        marketId: params.marketId
    });

    const offerChangeVariables = {
        id: params.id
    };

    const [queryParams, setQueryParams] = useQueryParams({
        schema: QueryParamsSchema,
        defaults: {
            page: PaginationInitialPageIndex,
            limit: PaginationDefaultPageSize
        }
    });

    const {
        getOfferChange,
        getOfferChangeProducts,
        getCategorySets,
        updateOfferChange,
        removeOfferChangeProducts,
        publishOfferChange,
        cancelOfferChange
    } = useProductCatalogService();

    const filters = useMemo(() => {
        return {
            search: queryParams.search,
            changeType: queryParams.changeType,
            types: queryParams.types,
            status: queryParams.status,
            categorySetIds: queryParams.categorySetIds,
            featureIds: queryParams.featureIds
        };
    }, [
        queryParams.search,
        queryParams.changeType,
        queryParams.types,
        queryParams.status,
        queryParams.categorySetIds,
        queryParams.featureIds
    ]);

    const offerChangeResult = usePromise({
        variables: offerChangeVariables,
        promiseBuilder: async (variables) => {
            return getOfferChange(variables);
        }
    });

    const offerChangeProductsResult = usePromise({
        variables: {
            ...offerChangeVariables,
            ...queryParams,
            offset: queryParams.page * queryParams.limit
        },
        promiseBuilder: async (variables) => {
            return getOfferChangeProducts(variables);
        }
    });

    const categorySetNamesResult = usePromise({
        variables: {
            categorySetIds: offerChangeProductsResult.data?.data?.flatMap(
                (product) => product.target.categorySetIds
            )
        },
        promiseBuilder: (variables) => {
            return getCategorySetNames({
                categorySetIds: variables.categorySetIds,
                onCategorySetsGet: async (requestVariables) => {
                    return await getCategorySets(requestVariables);
                }
            });
        },
        disableAutostart: !offerChangeProductsResult.data
    });

    // ---

    const handleOfferChangeUpdateComplete = async (
        completionParams: CompletionParams
    ) => {
        if (completionParams.error) {
            void errorNotification({
                error: completionParams.error,
                message: formatMessage(
                    Messages['common.error-message.offer-change-submit']
                )
            });

            return;
        }

        await offerChangeResult.execute(offerChangeVariables);

        setNameEditEnabled(false);

        void message.success(
            formatMessage(Messages['common.success-message.changes-saved'])
        );
    };

    const offerChangeUpdate = usePromise({
        variables: undefined as unknown as UpdateOfferChangeInput,
        promiseBuilder: async (variables) => {
            return updateOfferChange(variables);
        },
        disableAutostart: true,
        onComplete: handleOfferChangeUpdateComplete
    });

    // ---

    const handleOfferChangeRemoveProductsComplete = async (
        completionParams: CompletionParams<{ ids: ProductId[] }>
    ) => {
        if (completionParams.error) {
            void errorNotification({
                error: completionParams.error,
                message: formatMessage(
                    Messages['common.error-message.remove-products']
                )
            });

            return;
        }

        const removedProductsCount = completionParams.variables.ids.length;

        await offerChangeResult.execute(offerChangeVariables);
        await offerChangeProductsResult.execute({
            ...offerChangeVariables,
            ...queryParams,
            offset: 0
        });

        void message.success(
            formatMessage(Messages['common.success-message.remove-products'], {
                count: removedProductsCount
            })
        );
    };

    const offerChangeRemoveProducts = usePromise({
        variables: undefined as unknown as RemoveOfferChangeProductsInput,
        promiseBuilder: async (variables) => {
            return removeOfferChangeProducts(variables);
        },
        disableAutostart: true,
        onComplete: handleOfferChangeRemoveProductsComplete
    });

    // ---

    const handlePublishOfferChangeComplete = async (
        completionParams: CompletionParams
    ) => {
        if (completionParams.error) {
            void errorNotification({
                error: completionParams.error,
                message: formatMessage(
                    Messages['common.error-message.offer-change-publish']
                )
            });

            return;
        }

        await offerChangeResult.execute(offerChangeVariables);

        void message.success(
            formatMessage(
                Messages['common.success-message.offer-change-publish']
            )
        );
    };

    const offerChangePublish = usePromise({
        variables: undefined as unknown as PublishOfferChangeInput,
        promiseBuilder: async (variables) => {
            return publishOfferChange(variables);
        },
        onComplete: handlePublishOfferChangeComplete,
        disableAutostart: true
    });

    // ---

    const handleCancelOfferChangeComplete = async (
        completionParams: CompletionParams
    ) => {
        if (completionParams.error) {
            void errorNotification({
                error: completionParams.error,
                message: formatMessage(
                    Messages['common.error-message.offer-change-cancel']
                )
            });

            return;
        }

        await offerChangeResult.execute(offerChangeVariables);

        void message.success(
            formatMessage(
                Messages['common.success-message.offer-change-cancel']
            )
        );
    };

    const offerChangeCancel = usePromise({
        variables: undefined as unknown as CancelOfferChangeInput,
        promiseBuilder: async (variables) => {
            return cancelOfferChange(variables);
        },
        onComplete: handleCancelOfferChangeComplete,
        disableAutostart: true
    });

    // ---

    const categorySetNamesLoading = categorySetNamesResult.loading;
    const featuresLoading = featuresData.loading && !featuresData.data;
    const productTypesLoading =
        productTypesData.loading && !productTypesData.data;
    const offerChangeLoading =
        offerChangeResult.loading && !offerChangeResult.data;
    const offerChangeProductsLoading =
        offerChangeProductsResult.loading && !offerChangeProductsResult.data;

    const loading =
        categorySetNamesLoading ||
        featuresLoading ||
        productTypesLoading ||
        offerChangeLoading ||
        offerChangeProductsLoading;

    if (loading) {
        return <Loader type="center" />;
    }

    const categorySetNamesMap = categorySetNamesResult.data;
    const features = featuresData.data;
    const featurePropertyValidationMap =
        featuresData.featurePropertyValidationMap;
    const productTypes = productTypesData.data;
    const offerChange = offerChangeResult.data?.data;
    const offerChangeProducts = offerChangeProductsResult.data?.data;
    const productsTotalCount =
        offerChangeProductsResult.data?.metadata?.totalCount;

    const error = offerChangeResult.error;

    if (error) {
        return (
            <Result
                status={error.status === 404 ? '404' : 'error'}
                title={error.status}
                subTitle={error?.details}
                extra={
                    <Button type="primary" onClick={() => navigate(listLink)}>
                        {formatMessage(
                            Messages['common.button.go-to-offer-change-list']
                        )}
                    </Button>
                }
            />
        );
    }

    if (
        !categorySetNamesMap ||
        !features ||
        !featurePropertyValidationMap ||
        !productTypes ||
        !offerChange ||
        !offerChangeProducts ||
        productsTotalCount === undefined
    ) {
        return null;
    }

    const offerChangeStatus = offerChange?.status;

    const breadcrumbRoutes = buildBreadcrumbsRoutes(
        offerChange.id,
        params.marketId,
        intl
    );

    const handlePageIndexChange = (page: number) => {
        setQueryParams({ page });
    };

    const handlePageSizeChange = (limit: number) => {
        const page = Math.floor((queryParams.page * queryParams.limit) / limit);
        setQueryParams({ page, limit });
    };

    const handleFiltersChange = (filters: Filters) => {
        setQueryParams({
            ...filters,
            page: 0
        });
    };

    const handleNameEditSubmit = async (name: string) => {
        await offerChangeUpdate.execute({
            name,
            id: offerChange.id,
            expectedVersion: offerChange.version
        });
    };

    const handleOfferChangePublish = async () => {
        await offerChangePublish.execute({
            id: offerChange.id,
            expectedVersion: offerChange.version
        });
    };

    const handleOfferChangeCancel = async () => {
        await offerChangeCancel.execute({
            id: offerChange.id,
            expectedVersion: offerChange.version
        });
    };

    const handleRemoveSelectedProducts = async (ids: ProductId[]) => {
        await offerChangeRemoveProducts.execute({
            id: offerChange.id,
            expectedVersion: offerChange.version,
            ids
        });
    };

    const offerChangeDetailsProps = {
        marketId: params.marketId,
        offerChange,
        offerChangeProducts,
        productsTotalCount: productsTotalCount,
        breadcrumbRoutes,
        categorySetNamesMap,
        features,
        productTypes,
        pageIndex: queryParams.page,
        pageSize: queryParams.limit,
        filters,
        onPageIndexChange: handlePageIndexChange,
        onPageSizeChange: handlePageSizeChange,
        onFiltersChange: handleFiltersChange
    };

    const draftOfferChangeDetailsProps = {
        ...offerChangeDetailsProps,
        nameEditLoading: offerChangeUpdate.loading,
        nameEditEnabled: nameEditEnabled,
        onNameEditToggle: setNameEditEnabled,
        onNameEditSubmit: handleNameEditSubmit,
        onOfferChangePublish: handleOfferChangePublish,
        onOfferChangeCancel: handleOfferChangeCancel,
        onProductsRemove: handleRemoveSelectedProducts
    };

    switch (offerChangeStatus) {
        case OfferChangeStatus.Draft:
            return (
                <DraftOfferChangeDetailsLayout
                    {...draftOfferChangeDetailsProps}
                />
            );
        case OfferChangeStatus.Published:
        case OfferChangeStatus.Cancelled:
            return (
                <FinishedOfferChangeDetailsLayout
                    {...offerChangeDetailsProps}
                />
            );
    }
};
