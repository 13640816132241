import queryString, { StringifiableRecord } from 'query-string';
import { DefaultJsonContentType } from './constans';
import { HTTPError } from './error';
import { MarketId } from './models';

export interface RequestContext {
    baseUrl: string;
    accessToken?: string;
    market?: MarketId;
}

const getAuthHeaders = (context?: RequestContext) => {
    if (!context?.accessToken) {
        return undefined;
    }

    return {
        Authorization: `Bearer ${context.accessToken}`
    };
};

const getMarketHeaders = (context?: RequestContext) => {
    if (!context?.market) {
        return undefined;
    }

    return {
        market: context?.market
    };
};

interface ApiErrorResponse {
    detailedMessage: string;
    message: string;
}

interface FetchDataOptions {
    path: string;
    context: RequestContext;
    baseInit?: Parameters<typeof fetch>[1];
    query?: StringifiableRecord;
}

export const fetchData = async <RequestResult>({
    path,
    context,
    baseInit,
    query
}: FetchDataOptions) => {
    const authHeaders = getAuthHeaders(context);
    const marketHeaders = getMarketHeaders(context);

    const url = queryString.stringifyUrl({
        url: `${context.baseUrl}${path}`,
        query
    });

    const init = {
        ...baseInit,
        headers: {
            ...baseInit?.headers,
            ...authHeaders,
            ...marketHeaders
        }
    };

    const response = await fetch(url, init);

    const contentType = response.headers.get('Content-Type');
    const isJsonBody = contentType?.startsWith(DefaultJsonContentType);

    if (!response.ok) {
        const { status, statusText } = response;

        if (isJsonBody) {
            const reasonJson = (await response.json()) as ApiErrorResponse;

            throw new HTTPError(
                statusText,
                status,
                reasonJson.detailedMessage || reasonJson.message
            );
        } else {
            const reasonText = await response.text();
            throw new HTTPError(statusText, status, reasonText);
        }
    }

    if (isJsonBody) {
        return (await response.json()) as RequestResult;
    }

    return undefined as unknown as RequestResult;
};
