import { useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    AugmentedTableInterface,
    Table,
    TablePaginationConfig,
    Tag
} from 'antd';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import {
    CategorySetId,
    Feature,
    GetProductsInput,
    ProductType
} from '@olxeu-monetization/product-catalog-api-client';
import { ModalTitle } from '../../components/ModalTitle';
import { ModalContext } from '../../context';
import { getCategorySetNames, useProductCatalogService } from '../../helpers';
import { Messages } from '../../intl';
import {
    ActionType,
    buildTableColumns,
    TableDataSource,
    TableFilters
} from '../../layouts/ProductListLayout/columns';
import {
    ActionLinkOptions,
    buildFeatureOptions,
    buildProductsDataSource,
    buildTypeOptions,
    mapTableFiltersToFilters
} from '../../layouts/ProductListLayout/utils';
import { MarketId } from '../../routing';
import { theme } from '../../styles';
import { usePromise } from '../../utils';

const PaginationInitialPageIndex = 0;
const PaginationDefaultPageSize = 20;

const ProductTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

interface QueryParams extends GetProductsInput {
    page: number;
    limit: number;
}

interface Props {
    marketId: MarketId;
    features: Feature[];
    productTypes: ProductType[];
    actionOptions: ActionLinkOptions;
}

export const ProductSelectionModal = ({
    marketId,
    features,
    productTypes,
    actionOptions
}: Props) => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const { onClose } = useContext(ModalContext);
    const [queryParams, setQueryParams] = useState<QueryParams>({
        page: PaginationInitialPageIndex,
        limit: PaginationDefaultPageSize
    });

    const { getProducts, getCategorySets } = useProductCatalogService();

    const productsResult = usePromise({
        variables: {
            ...queryParams,
            offset: queryParams.page * queryParams.limit
        },
        promiseBuilder: async (variables) => {
            return getProducts(variables);
        }
    });

    const categorySetNamesResult = usePromise({
        variables: {
            categorySetIds: productsResult.data?.data?.flatMap(
                (product) => product.target.categorySetIds
            )
        },
        promiseBuilder: (variables) => {
            return getCategorySetNames({
                categorySetIds: variables.categorySetIds,
                onCategorySetsGet: async (requestVariables) => {
                    return await getCategorySets(requestVariables);
                }
            });
        },
        disableAutostart: !productsResult.data
    });

    const filters = useMemo(() => {
        return {
            search: queryParams.search,
            types: queryParams.types,
            status: queryParams.status,
            categorySetIds: queryParams.categorySetIds,
            featureIds: queryParams.featureIds
        };
    }, [
        queryParams.search,
        queryParams.types,
        queryParams.status,
        queryParams.categorySetIds,
        queryParams.featureIds
    ]);

    const productsLoading = productsResult.loading && !productsResult.data;
    const categorySetNamesLoading =
        categorySetNamesResult.loading && !categorySetNamesResult.data;
    const products = productsResult.data?.data ?? [];
    const productsCount = productsResult.data?.metadata?.totalCount ?? 0;
    const categorySetNames =
        categorySetNamesResult.data ?? new Map<CategorySetId, string>();

    const featureOptions = buildFeatureOptions(features);
    const typeOptions = buildTypeOptions(productTypes);
    const dataSource = buildProductsDataSource(
        products,
        categorySetNames,
        marketId,
        actionOptions
    );

    const pageSize = queryParams.limit;
    const pageIndex = queryParams.page;

    const handleChange = (
        pagination: TablePaginationConfig,
        filters: TableFilters,
        sorter: SorterResult<TableDataSource> | SorterResult<TableDataSource>[],
        extra: TableCurrentDataSource<TableDataSource>
    ) => {
        switch (extra.action) {
            case 'paginate': {
                const nextPageIndex = Number(pagination.current) - 1;
                const nextPageSize = Number(pagination.pageSize);

                if (nextPageIndex !== pageIndex) {
                    setQueryParams((queryParams) => ({
                        ...queryParams,
                        page: nextPageIndex
                    }));
                }

                if (nextPageSize !== pageSize) {
                    setQueryParams((queryParams) => ({
                        ...queryParams,
                        limit: nextPageSize
                    }));
                }

                break;
            }
            case 'filter': {
                const nextFilters = mapTableFiltersToFilters(
                    filters,
                    featureOptions
                );

                setQueryParams((queryParams) => ({
                    ...queryParams,
                    ...nextFilters
                }));

                break;
            }
            default:
                return;
        }
    };

    const columns = buildTableColumns({
        marketId,
        featureOptions,
        typeOptions,
        filters,
        intl,
        actionType: ActionType.Edit,
        onActionClick: onClose
    });

    return (
        <>
            <ModalTitle
                title={formatMessage(Messages['common.label.select-product'])}
                tags={
                    <Tag color={theme.colors.tagCounter}>
                        {formatMessage(Messages['common.tag.available'], {
                            count: productsCount
                        })}
                    </Tag>
                }
            />
            <ProductTable
                data-testid="select-product-table"
                columns={columns}
                dataSource={dataSource}
                loading={productsLoading || categorySetNamesLoading}
                pagination={{
                    total: productsCount,
                    pageSize,
                    current: pageIndex + 1
                }}
                onChange={handleChange}
            />
        </>
    );
};
