import { Button, Divider, Typography } from 'antd';
import { SlackOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { PriceProjectLogo } from '../components/Icon';

const { Title, Text } = Typography;

const PageWrapper = styled.section`
    width: 100%;
    height: 100%;
    background: linear-gradient(315deg, #001529 20%, #000c17 80%);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const BoxWrapper = styled.div`
    ${({ theme }) => `
        padding: ${theme.space[48]};
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
    `};
`;

const StyledHeader = styled.div`
    ${({ theme }) => `
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: ${theme.space[24]};
    `};
`;

const H1 = styled(Title)`
    &.ant-typography {
        ${({ theme }) => `
        color: ${theme.colors.white};
        margin: ${theme.space[24]} 0;
    `};
    }
`;

const StyledDivider = styled(Divider)`
    &.ant-divider {
        ${({ theme }) => `
        border-top-color: ${theme.colors.white10p};
    `};
    }
`;

const DividerText = styled(Text)`
    &.ant-typography {
        ${({ theme }) => `
        color: ${theme.colors.white70p};
        margin: ${theme.space[8]} 0;
    `};
    }
`;

const StyledButton = styled(Button)`
    ${({ theme }) => `
        width: 100%;
        height: 48px;
        color: ${theme.colors.darkBlue};
        font-size: ${theme.fontSizes.large};
        font-weight: bold;
    `};
`;

interface Props {
    onLogin?: () => void;
}

export const SignInLayoutOkta = ({ onLogin }: Props) => {
    return (
        <PageWrapper data-testid="sign-in-layout">
            <BoxWrapper>
                <StyledHeader>
                    <PriceProjectLogo role="img" fontSize={72} />
                    <H1 level={3}>Welcome to Monetization Hub</H1>
                </StyledHeader>
                <StyledButton size="large" onClick={() => onLogin?.()}>
                    Login with OKTA
                </StyledButton>
                <StyledDivider plain>
                    <DividerText>or</DividerText>
                </StyledDivider>
                <a
                    href="https://olxgroup.enterprise.slack.com/archives/C9G75B1J4"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <StyledButton size="large" icon={<SlackOutlined />}>
                        Contact us on Slack
                    </StyledButton>
                </a>
            </BoxWrapper>
        </PageWrapper>
    );
};
