import { CategoryId, LimitId, CategorySetId } from './types';

export enum MarketId {
    OLXPoland = 'olxpl',
    OLXUkraine = 'olxua',
    OLXKazakhstan = 'olxkz',
    OLXUzbekistan = 'olxuz',
    OLXPortugal = 'olxpt',
    OLXBulgaria = 'olxbg',
    OLXRomania = 'olxro'
}

export enum LimitStatus {
    Active = 'active',
    Disabled = 'disabled'
}

export enum LimitType {
    Default = 'default',
    Business = 'business',
    Extra = 'extra'
}

export enum LimitLabelLanguage {
    English = 'en',
    Polish = 'pl',
    Bulgarian = 'bg',
    Ukrainian = 'uk',
    Russian = 'ru',
    Portuguese = 'pt',
    Romanian = 'ro',
    Kazakh = 'kz',
    Uzbek = 'uz'
}

export type LimitLabelMap = {
    name: Partial<Record<LimitLabelLanguage, string>>;
};

export const UnlimitedOption = 'unlimited';

export interface Limit {
    id: LimitId;
    limit_type: LimitType;
    categories_configuration: string[];
    categories: CategoryId[];
    capacity: number;
    validity_period: string;
    labels: LimitLabelMap;
    tenant_id: string;
    status: LimitStatus;
    updated_at: string;
    product_category_set_id: CategorySetId;
    options: string[];
}

export interface LimitsByCS {
    items: Limit[];
}
