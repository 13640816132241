import { IntlShape, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { LimitId } from '@olxeu-monetization/user-product-service-api-client';
import { Loader } from '../../components/Loader';
import { useProductCatalogService, useUpsService } from '../../helpers';
import { Messages } from '../../intl';
import { LimitDetailsLayout } from '../../layouts/LimitDetailsLayout';
import { buildLink, MarketId, Path, PathParams } from '../../routing';
import { arrangeLimits, usePromise } from '../../utils';
import { buildBreadcrumbsRoutes as buildUpstreamBreadcrumbsRoutes } from '../LimitListPage';

type Params = PathParams[Path.LimitDetails];

const buildBreadcrumbsRoutes = (
    limitId: LimitId,
    marketId: MarketId,
    intl: IntlShape
) => {
    return [
        ...buildUpstreamBreadcrumbsRoutes(marketId, intl),
        {
            path: buildLink(Path.CategorySetDetails, {
                id: limitId,
                marketId
            }),
            breadcrumbName: intl.formatMessage(Messages['common.label.limit'])
        }
    ];
};

export const LimitDetailsPage = () => {
    const params = useParams<Params>();
    const intl = useIntl();

    const { getLimits, getLimit } = useUpsService();
    const { getCategorySets } = useProductCatalogService();

    const limitVariables = {
        id: params.id
    };

    const defaultLimitResult = usePromise({
        variables: limitVariables,
        promiseBuilder: async (variables) => {
            return getLimit(variables);
        }
    });

    const allLimitsQueryVariables = {
        product_category_set_ids: [
            String(defaultLimitResult.data?.product_category_set_id)
        ]
    };

    const allLimitsResult = usePromise({
        variables: allLimitsQueryVariables,
        promiseBuilder: async (allLimitsQueryVariables) => {
            return getLimits(allLimitsQueryVariables);
        },
        disableAutostart: !defaultLimitResult.data
    });

    const categorySetResult = usePromise({
        variables: {
            ids: defaultLimitResult.data
                ? [defaultLimitResult.data.product_category_set_id]
                : [],
            offset: 0
        },
        promiseBuilder: async (variables) => {
            return getCategorySets(variables);
        },
        disableAutostart: !defaultLimitResult.data
    });

    const limitsLoading = allLimitsResult.loading && !allLimitsResult.data;
    const categorySetLoading =
        categorySetResult.loading && !categorySetResult.data;

    if (limitsLoading || categorySetLoading) {
        return <Loader type="center" />;
    }

    const allLimits = arrangeLimits(allLimitsResult.data?.items);
    const categorySet = categorySetResult.data?.data[0];

    if (!allLimits || !categorySet) return null;

    const breadcrumbRoutes = buildBreadcrumbsRoutes(
        params.id,
        params.marketId,
        intl
    );

    return (
        <LimitDetailsLayout
            limits={allLimits}
            marketId={params.marketId}
            breadcrumbRoutes={breadcrumbRoutes}
            categorySet={categorySet}
        />
    );
};
