import { useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { AuthContext, ConfigContext } from '../../context';
import { SignInLayout } from '../../layouts/SignInLayout';
import { SignInLayoutOkta } from '../../layouts/SignInLayoutOkta';
import { getOktaMarketIds } from '../../providers';
import { MarketId } from '../../routing';

export const SignInPage = () => {
    const [search] = useSearchParams();
    const isGlobalParam = search.get('global') === 'true';

    const { oktaAuth } = useOktaAuth();

    const configContext = useContext(ConfigContext);
    const authContext = useContext(AuthContext);

    const markets = useMemo(() => {
        return getOktaMarketIds(
            configContext.services.oktaAuthService.marketConfigs
        );
    }, [configContext.services.oktaAuthService.marketConfigs]);

    const handleMarketSelect = (marketId: MarketId) => {
        authContext.openAuthenticationPage(marketId);
    };

    const handleLogin = () => {
        void oktaAuth.signInWithRedirect({
            clientId: configContext.services.oktaAuthService.clientId
        });
    };

    if (isGlobalParam) {
        return <SignInLayoutOkta onLogin={handleLogin} />;
    }

    const eligibleMarkets = markets.filter(
        (marketId) => marketId !== MarketId.Global
    );

    return (
        <SignInLayout
            markets={eligibleMarkets}
            onMarketSelect={handleMarketSelect}
        />
    );
};
