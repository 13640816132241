import { useContext } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Result } from 'antd';
import { ProductId } from '@olxeu-monetization/product-catalog-api-client';
import { Loader } from '../../components/Loader';
import {
    FeaturesDataContext,
    MarketLanguagesDataContext,
    ProductTypesDataContext
} from '../../context';
import { useProductCatalogService } from '../../helpers';
import { Messages } from '../../intl';
import { ProductDetailsLayout } from '../../layouts/ProductDetailsLayout';
import { createProductTypeTree } from '../../layouts/ProductFormLayout/helpers';
import { buildLink, MarketId, Path, PathParams } from '../../routing';
import { usePromise } from '../../utils';
import { buildBreadcrumbsRoutes as buildUpstreamBreadcrumbsRoutes } from '../ProductListPage';

type Params = PathParams[Path.ProductDetails];

export const buildBreadcrumbsRoutes = (
    productId: ProductId,
    marketId: MarketId,
    intl: IntlShape
) => {
    return [
        ...buildUpstreamBreadcrumbsRoutes(marketId, intl),
        {
            path: buildLink(Path.ProductDetails, { id: productId, marketId }),
            breadcrumbName: intl.formatMessage(Messages['common.label.product'])
        }
    ];
};

export const ProductDetailsPage = () => {
    const intl = useIntl();
    const marketLanguagesData = useContext(MarketLanguagesDataContext);
    const featuresData = useContext(FeaturesDataContext);
    const productTypesData = useContext(ProductTypesDataContext);
    const params = useParams<Params>();

    const { getProduct } = useProductCatalogService();

    const navigate = useNavigate();

    const listLink = buildLink(Path.ProductList, {
        marketId: params.marketId
    });

    const productVariables = {
        id: params.id
    };

    const productResult = usePromise({
        variables: productVariables,
        promiseBuilder: async (variables) => {
            return getProduct(variables);
        }
    });

    const marketLanguagesLoading =
        marketLanguagesData.loading && !marketLanguagesData.data;
    const featuresLoading = featuresData.loading && !featuresData.data;
    const productTypesLoading =
        productTypesData.loading && !productTypesData.data;
    const productLoading = productResult.loading && !productResult.data;

    const loading =
        marketLanguagesLoading ||
        featuresLoading ||
        productTypesLoading ||
        productLoading;

    if (loading) {
        return <Loader type="center" />;
    }

    const marketLanguages = marketLanguagesData.data?.current;
    const features = featuresData.data;
    const featurePropertyValidationMap =
        featuresData.featurePropertyValidationMap;
    const productTypes = productTypesData.data;
    const product = productResult.data?.data;
    const error = productResult.error;

    if (error) {
        return (
            <Result
                status={error.status === 404 ? '404' : 'error'}
                title={error.status}
                subTitle={error?.details}
                extra={
                    <Button type="primary" onClick={() => navigate(listLink)}>
                        {intl.formatMessage(
                            Messages['common.button.go-to-product-list']
                        )}
                    </Button>
                }
            />
        );
    }

    if (
        !marketLanguages ||
        !features ||
        !featurePropertyValidationMap ||
        !productTypes ||
        !product
    ) {
        return null;
    }

    const breadcrumbRoutes = buildBreadcrumbsRoutes(
        product.id,
        params.marketId,
        intl
    );

    const productTypeTree = createProductTypeTree(productTypes);

    return (
        <ProductDetailsLayout
            title={intl.formatMessage(Messages['common.label.product'])}
            product={product}
            features={features}
            productTypeTree={productTypeTree}
            featurePropertyValidationMap={featurePropertyValidationMap}
            breadcrumbRoutes={breadcrumbRoutes}
            marketLanguages={marketLanguages}
            marketId={params.marketId}
        />
    );
};
