import { ReactNode, useContext, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { AuthContext, ConfigContext } from '../../context';
import {
    getOktaMarketIds,
    OktaAccessScopeGrant,
    parseAccessRoleGrant
} from '../../providers';
import {
    getMarketsFromJwtRoles,
    isScopeEligibleForRole,
    parseAccessScopeGrant
} from '../../providers/AuthContextProvider/helpers';
import { MarketId, Path } from '../paths';
import { AuthenticationRedirection } from '../redirections';

type Params = {
    marketId: MarketId;
};

interface Props {
    children?: ReactNode;
}

export const MarketGuard = ({ children }: Props) => {
    const auth = useContext(AuthContext);
    const config = useContext(ConfigContext);
    const params = useParams<Params>();

    const configMarketIds = useMemo(() => {
        return getOktaMarketIds(config.services.oktaAuthService.marketConfigs);
    }, [config.services.oktaAuthService.marketConfigs]);

    let marketId: MarketId | undefined;

    if (auth.isUsingSingleOktaClient) {
        const marketIds = getMarketsFromJwtRoles(
            auth.userMarketScopes ?? [],
            MarketId
        );
        marketId = marketIds.find((marketId) => {
            return marketId === params.marketId;
        });
    } else {
        marketId = configMarketIds.find((configMarketId) => {
            return configMarketId === params.marketId;
        });
    }

    // NOTE: market requested in the URL doesn't exist
    if (marketId === undefined) {
        return <Navigate to={Path.Home} />;
    }

    // NOTE: user is not authorized for requested market
    if (auth.userMarketId !== marketId && !auth.isUsingSingleOktaClient) {
        return <AuthenticationRedirection marketId={marketId} />;
    }

    // NOTE: user is not authorized for requested tool
    const hasProductCatalogReadAccess = auth.userMarketScopes?.some((scope) => {
        if (auth.isUsingSingleOktaClient) {
            const userRole = parseAccessRoleGrant(scope);

            if (userRole === undefined) {
                return false;
            }

            return isScopeEligibleForRole(
                userRole,
                OktaAccessScopeGrant.ProductCatalogRead
            );
        }

        const grant = parseAccessScopeGrant(scope);
        return grant === OktaAccessScopeGrant.ProductCatalogRead;
    });

    if (!hasProductCatalogReadAccess) {
        throw new Error(
            'User has no access to Product Catalog for this market'
        );
    }

    return <>{children}</>;
};
