import { useContext } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import {
    OfferChangeId,
    ProductChangeType,
    ProductId
} from '@olxeu-monetization/product-catalog-api-client';
import { Loader } from '../../components/Loader';
import {
    FeaturesDataContext,
    MarketLanguagesDataContext,
    ProductTypesDataContext
} from '../../context';
import { useProductCatalogService } from '../../helpers';
import { Messages } from '../../intl';
import { EditedProductDetailsLayout } from '../../layouts/EditedProductDetailsLayout';
import { ProductDetailsLayout } from '../../layouts/ProductDetailsLayout';
import { createProductTypeTree } from '../../layouts/ProductFormLayout/helpers';
import { buildLink, MarketId, Path, PathParams } from '../../routing';
import { usePromise } from '../../utils';
import { buildBreadcrumbsRoutes as buildUpstreamBreadcrumbsRoutes } from '../OfferChangeListPage';

type Params = PathParams[Path.OfferChangeProductDetails];

const buildBreadcrumbsRoutes = (
    id: OfferChangeId,
    productId: ProductId,
    marketId: MarketId,
    intl: IntlShape
) => {
    return [
        ...buildUpstreamBreadcrumbsRoutes(marketId, intl),
        {
            path: buildLink(Path.OfferChangeDetails, {
                id,
                marketId
            }),
            breadcrumbName: intl.formatMessage(
                Messages['common.label.offer-change']
            )
        },
        {
            path: buildLink(Path.OfferChangeProductDetails, {
                id,
                productId,
                marketId
            }),
            breadcrumbName: intl.formatMessage(Messages['common.label.product'])
        }
    ];
};

export const OfferChangeProductDetailsPage = () => {
    const intl = useIntl();

    const marketLanguagesData = useContext(MarketLanguagesDataContext);
    const featuresData = useContext(FeaturesDataContext);
    const productTypesData = useContext(ProductTypesDataContext);

    const params = useParams<Params>();

    const { getOfferChangeProduct, getProductAtVersion } =
        useProductCatalogService();

    const productVariables = {
        id: params.id,
        productId: params.productId
    };

    const productResult = usePromise({
        variables: productVariables,
        promiseBuilder: async (variables) => {
            return getOfferChangeProduct(variables);
        }
    });

    const shouldLoadPreviousProduct =
        productResult.data?.metadata.previousVersion !== undefined &&
        productResult.data?.data.changeType === ProductChangeType.Updated;

    const previousProductResult = usePromise({
        variables: {
            id: params.productId,
            version: productResult.data?.metadata.previousVersion as number
        },
        promiseBuilder: async (variables) => {
            return getProductAtVersion(variables);
        },
        disableAutostart: !shouldLoadPreviousProduct
    });

    const marketLanguagesLoading =
        marketLanguagesData.loading && !marketLanguagesData.data;
    const featuresLoading = featuresData.loading && !featuresData.data;
    const productTypesLoading =
        productTypesData.loading && !productTypesData.data;
    const productLoading = productResult.loading && !productResult.data;
    const previousProductLoading =
        previousProductResult.loading && !previousProductResult.data;

    const loading =
        marketLanguagesLoading ||
        featuresLoading ||
        productTypesLoading ||
        productLoading ||
        previousProductLoading;

    if (loading) {
        return <Loader type="center" />;
    }

    const marketLanguages = marketLanguagesData.data?.current;
    const features = featuresData.data;
    const featurePropertyValidationMap =
        featuresData.featurePropertyValidationMap;
    const productTypes = productTypesData.data;
    const product = productResult?.data?.data;
    const previousProduct = previousProductResult.data?.data;

    const isProductPublished =
        productResult.data?.metadata.publishedVersion !== undefined;

    if (
        !marketLanguages ||
        !features ||
        !featurePropertyValidationMap ||
        !productTypes ||
        !product
    ) {
        return null;
    }

    const breadcrumbRoutes = buildBreadcrumbsRoutes(
        params.id,
        params.productId,
        params.marketId,
        intl
    );

    const productTypeTree = createProductTypeTree(productTypes);

    switch (product.changeType) {
        case ProductChangeType.NewProduct:
            return (
                <ProductDetailsLayout
                    title={intl.formatMessage(
                        Messages['common.label.created-product']
                    )}
                    showEditButton={isProductPublished}
                    product={product}
                    features={features}
                    featurePropertyValidationMap={featurePropertyValidationMap}
                    productTypeTree={productTypeTree}
                    breadcrumbRoutes={breadcrumbRoutes}
                    marketLanguages={marketLanguages}
                    marketId={params.marketId}
                />
            );
        case ProductChangeType.Updated:
            if (!shouldLoadPreviousProduct) {
                throw new Error('Product has no previous version associated.');
            }

            if (!previousProduct) {
                return null;
            }

            return (
                <EditedProductDetailsLayout
                    product={product}
                    previousProduct={previousProduct}
                    features={features}
                    featurePropertyValidationMap={featurePropertyValidationMap}
                    productTypeTree={productTypeTree}
                    breadcrumbRoutes={breadcrumbRoutes}
                    marketLanguages={marketLanguages}
                />
            );
    }
};
