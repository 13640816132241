import { useContext } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, message, Result } from 'antd';
import {
    MarketId,
    RemoveTemplateInput,
    TemplateId
} from '@olxeu-monetization/product-catalog-api-client';
import { Loader } from '../../components/Loader';
import { MarketLanguagesDataContext } from '../../context';
import { useProductCatalogService } from '../../helpers';
import { Messages } from '../../intl';
import { TemplateDetailsLayout } from '../../layouts/TemplateDetailsLayout';
import { buildLink, Path, PathParams } from '../../routing';
import { CompletionParams, errorNotification, usePromise } from '../../utils';
import { buildBreadcrumbsRoutes as buildUpstreamBreadcrumbsRoutes } from '../TemplateListPage';

type Params = PathParams[Path.TemplateDetails];

const buildBreadcrumbsRoutes = (
    categorySetId: TemplateId,
    marketId: MarketId,
    intl: IntlShape
) => {
    return [
        ...buildUpstreamBreadcrumbsRoutes(marketId, intl),
        {
            path: buildLink(Path.TemplateDetails, {
                id: categorySetId,
                marketId
            }),
            breadcrumbName: intl.formatMessage(
                Messages['common.label.template']
            )
        }
    ];
};

export const TemplateDetailsPage = () => {
    const intl = useIntl();
    const marketLanguagesData = useContext(MarketLanguagesDataContext);
    const params = useParams<Params>();
    const { formatMessage } = useIntl();

    const navigate = useNavigate();

    const { getTemplate, removeTemplate } = useProductCatalogService();

    const listLink = buildLink(Path.TemplateList, {
        marketId: params.marketId
    });

    const templateResult = usePromise({
        variables: {
            id: params.id
        },
        promiseBuilder: async (variables) => {
            return getTemplate(variables);
        }
    });

    const handleTemplateRemoveComplete = (
        completionParams: CompletionParams<{ id: TemplateId }>
    ) => {
        if (completionParams.error) {
            void errorNotification({
                error: completionParams.error,
                message: formatMessage(
                    Messages['common.error-message.remove-template']
                )
            });
            return;
        }

        void message.success(
            formatMessage(Messages['common.success-message.remove-template'])
        );

        navigate(listLink);
    };

    const templateRemove = usePromise({
        variables: undefined as unknown as RemoveTemplateInput,
        promiseBuilder: async (variables) => {
            return removeTemplate(variables);
        },
        disableAutostart: true,
        onComplete: handleTemplateRemoveComplete
    });

    const handleTemplateRemove = async (id: TemplateId) => {
        await templateRemove.execute({
            id
        });
    };

    const languagesLoading =
        marketLanguagesData.loading && !marketLanguagesData.data;
    const templateLoading = templateResult.loading && !templateResult.data;

    const loading = languagesLoading || templateLoading;

    if (loading) {
        return <Loader type="center" />;
    }

    const languages = marketLanguagesData.data;
    const template = templateResult.data?.data;
    const error = templateResult.error;

    if (error) {
        return (
            <Result
                status={error.status === 404 ? '404' : 'error'}
                title={error.status}
                subTitle={error?.details}
                extra={
                    <Button type="primary" onClick={() => navigate(listLink)}>
                        {formatMessage(
                            Messages['common.button.go-to-template-list']
                        )}
                    </Button>
                }
            />
        );
    }

    if (!languages || !template) {
        return null;
    }

    const breadcrumbRoutes = buildBreadcrumbsRoutes(
        params.id,
        params.marketId,
        intl
    );

    return (
        <TemplateDetailsLayout
            template={template}
            languages={languages.available}
            defaultLanguage={languages.default}
            marketId={params.marketId}
            breadcrumbRoutes={breadcrumbRoutes}
            onRemove={handleTemplateRemove}
        />
    );
};
