import { ReactNode, useContext, useMemo } from 'react';
import { AuthContext, CategoriesDataContext } from '../../context';
import { useProductCatalogService } from '../../helpers';
import { usePromise } from '../../utils';

interface Props {
    children?: ReactNode;
}

export const CategoriesDataContextProvider = ({ children }: Props) => {
    const auth = useContext(AuthContext);

    const { getCategories } = useProductCatalogService();

    const { data, loading, error } = usePromise({
        disableAutostart: !auth.authenticated,
        variables: undefined,
        promiseBuilder: async () => {
            return getCategories();
        }
    });

    const context = useMemo(() => {
        return {
            data: data?.data,
            loading,
            error
        };
    }, [data?.data, error, loading]);

    return (
        <CategoriesDataContext.Provider value={context}>
            {children}
        </CategoriesDataContext.Provider>
    );
};
