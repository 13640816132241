import { useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { LimitType } from '@olxeu-monetization/user-product-service-api-client';
import { Loader } from '../../components/Loader';
import { buildHomeBreadcrumbRoute } from '../../components/PageHeader';
import { getCategorySetNames, useProductCatalogService } from '../../helpers';
import { useUpsService } from '../../helpers';
import { Messages } from '../../intl';
import { Filters } from '../../layouts/LimitListLayout/columns';
import { LimitListLayout } from '../../layouts/LimitListLayout/LimitListLayout';
import {
    buildLink,
    MarketId,
    Path,
    PathParams,
    QueryParamsSchemas
} from '../../routing';
import { usePromise, useQueryParams } from '../../utils';

const QueryParamsSchema = QueryParamsSchemas[Path.LimitList];

type Params = PathParams[Path.LimitList];

export const buildBreadcrumbsRoutes = (marketId: MarketId, intl: IntlShape) => {
    return [
        buildHomeBreadcrumbRoute(marketId),
        {
            path: buildLink(Path.LimitList, { marketId }),
            breadcrumbName: intl.formatMessage(Messages['common.label.limits'])
        }
    ];
};

const PaginationInitialPageIndex = 0;
const PaginationDefaultPageSize = 10;

export const LimitListPage = () => {
    const intl = useIntl();
    const params = useParams<Params>();

    const { getLimits } = useUpsService();
    const { getCategorySets } = useProductCatalogService();

    const breadcrumbRoutes = buildBreadcrumbsRoutes(params.marketId, intl);

    const handlePageIndexChange = (page: number) => {
        setQueryParams({ page });
    };

    const handlePageSizeChange = (limit: number) => {
        const page = Math.floor((queryParams.page * queryParams.limit) / limit);
        setQueryParams({ page, limit });
    };

    const [queryParams, setQueryParams] = useQueryParams({
        schema: QueryParamsSchema,
        defaults: {
            page: PaginationInitialPageIndex,
            limit: PaginationDefaultPageSize
        }
    });

    const filters = useMemo(() => {
        return {
            status: queryParams.status,
            categorySetIds: queryParams.categorySetIds
        };
    }, [queryParams.status, queryParams.categorySetIds]);

    const limitsListVariables = {
        limit_status: filters.status,
        limit_type: LimitType.Default,
        limit: PaginationDefaultPageSize,
        offset: queryParams.page * queryParams.limit,
        product_category_set_ids: filters.categorySetIds
    };

    const allLimitsResult = usePromise({
        variables: {
            limit_status: filters.status,
            limit_type: LimitType.Default,
            limit: 9000
        },
        promiseBuilder: async (variables) => {
            return getLimits(variables);
        }
    });

    const limitsResult = usePromise({
        variables: limitsListVariables,
        promiseBuilder: async (variables) => {
            return getLimits(variables);
        }
    });

    const limits = limitsResult.data?.items;

    const categorySetNamesResult = usePromise({
        variables: {
            categorySetIds: limits?.map(
                (limit) => limit.product_category_set_id
            )
        },
        promiseBuilder: (variables) => {
            return getCategorySetNames({
                categorySetIds: variables.categorySetIds,
                onCategorySetsGet: async (requestVariables) => {
                    return await getCategorySets(requestVariables);
                }
            });
        },
        disableAutostart: !limits
    });

    const categorySetNames = categorySetNamesResult.data;

    const handleFiltersChange = (filters: Filters) => {
        setQueryParams({
            ...filters
        });
    };

    const isLoading = categorySetNamesResult.loading || limitsResult.loading;

    if (isLoading) return <Loader type="center" />;

    if (!limits || !categorySetNames) return null;

    return (
        <LimitListLayout
            marketId={params.marketId}
            limits={limits}
            isLoading={isLoading}
            categorySetNamesMap={categorySetNames}
            breadcrumbRoutes={breadcrumbRoutes}
            filters={filters}
            onFiltersChange={handleFiltersChange}
            pageIndex={queryParams.page}
            pageSize={queryParams.limit}
            onPageIndexChange={handlePageIndexChange}
            onPageSizeChange={handlePageSizeChange}
            totalLimitsCount={allLimitsResult.data?.items.length || 0}
        />
    );
};
