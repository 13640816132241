import { ReactNode, useContext, useMemo } from 'react';
import {
    Feature,
    FeaturePropertyValidation
} from '@olxeu-monetization/product-catalog-api-client';
import {
    AuthContext,
    FeaturePropertyValidationMap,
    FeaturesDataContext
} from '../../context';
import { useProductCatalogService } from '../../helpers';
import { usePromise } from '../../utils';

export const buildFeaturePropertyValidationMap = (data?: Feature[]) =>
    data?.reduce<FeaturePropertyValidationMap>((acc, item) => {
        acc[item.id] = item.properties.reduce<
            Record<string, FeaturePropertyValidation>
        >((propAcc, propItem) => {
            propAcc[propItem.name] = propItem.validation;

            return propAcc;
        }, {});

        return acc;
    }, {});

interface Props {
    children?: ReactNode;
}

export const FeaturesDataContextProvider = ({ children }: Props) => {
    const auth = useContext(AuthContext);

    const { getFeatures } = useProductCatalogService();

    const { data, loading, error } = usePromise({
        disableAutostart: !auth.authenticated,
        variables: {
            limit: 999 // TODO: figure out how to get rid of arbitrary limit and handle proper pagination
        },
        promiseBuilder: async (variables) => {
            return getFeatures(variables);
        }
    });

    const context = useMemo(() => {
        return {
            data: data?.data,
            featurePropertyValidationMap: buildFeaturePropertyValidationMap(
                data?.data
            ),
            loading,
            error
        };
    }, [data?.data, error, loading]);

    return (
        <FeaturesDataContext.Provider value={context}>
            {children}
        </FeaturesDataContext.Provider>
    );
};
