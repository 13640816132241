export const theme = {
    colors: {
        blue: '#1890ff',
        darkBlue: '#2d3446',
        darkBlueDarken5: '#202739',
        darkBluish: '#e9ebf1',
        darkerBlue: '#001529',
        darkerBlue50p: '#00152980',
        lightBlue: '#788195',
        lightBluish: '#f1f3f6',
        lightBluishDarken5: '#e4e6e9',
        lighterBluish: '#f5f6f8',
        lighterBluishLighten2: '#f6f8fb',
        lighterBluishDarken3: '#e9ebee',
        orange: '#fc9c00',
        pink: '#da0063',
        lightPink: '#ffeff9',
        green: '#4da200',
        lightGreen: '#f1fff0',
        white: '#ffffff',
        white90p: 'rgba(255, 255, 255, 0.9)',
        white70p: 'rgba(255, 255, 255, 0.7)',
        white10p: 'rgba(255, 255, 255, 0.1)',
        gray: '#a1a1a1',
        black30p: 'rgba(0, 0, 0, 0.3)',
        black10p: 'rgba(0, 0, 0, 0.1)',
        tagCounter: '#096dd9',
        tagIdentifier: '#047b65',
        tagChanges: '#ae8a02',
        tagStatusEnabled: '#1ac465',
        tagStatusPublished: '#1ac465',
        tagStatusDraft: '#50a4ff',
        tagStatusCancelled: '#f15c5e',
        tagStatusRemoved: '#f15c5e',
        validationError: '#ff4d4f',
        red: '#ff0000'
    },
    fontSizes: {
        12: '12px',
        large: '16px'
    },
    fontWeight: {
        bold: 'bold'
    },
    space: {
        0: '0',
        2: '2px',
        4: '4px',
        8: '8px',
        12: '12px',
        16: '16px',
        20: '20px',
        24: '24px',
        32: '32px',
        36: '36px',
        40: '40px',
        48: '48px'
    },
    sizes: {
        collapsedSidebarHeaderPadding: '104px',
        filterDropdown: '320px',
        headerHeight: '70px',
        sidebarWidth: '240px',
        sidebarWidthCollapsed: '80px',
        sidebarHeaderPadding: '264px',
        userIcon: '40px'
    },
    sizesRaw: {
        categoriesTableMaxHeight: 480,
        filterTreeMaxHeight: 240
    },
    transitions: {
        sidebar: '0.3s cubic-bezier(0.2, 0, 0, 1) 0s'
    },
    get borders() {
        return {
            black10p1: '1px solid rgba(0, 0, 0, 0.1)',
            dashedGray1: `1px dashed ${this.colors.gray}`,
            white4: `4px solid ${this.colors.white}`
        };
    },
    radii: {
        4: '4px',
        8: '8px'
    },
    zIndices: {
        sticky: 90,
        fixed: 100
    }
} as const;

export type ThemeType = typeof theme;
