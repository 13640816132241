import { useContext, useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { message, Modal } from 'antd';
import {
    CategorySetId,
    RemoveCategorySetsInput
} from '@olxeu-monetization/product-catalog-api-client';
import {
    GetLimitsInput,
    LimitType
} from '@olxeu-monetization/user-product-service-api-client';
import { Loader } from '../../components/Loader';
import { buildHomeBreadcrumbRoute } from '../../components/PageHeader';
import { CategoriesDataContext } from '../../context';
import { useProductCatalogService, useUpsService } from '../../helpers';
import { Messages } from '../../intl';
import {
    CategorySetListLayout,
    Filters
} from '../../layouts/CategorySetListLayout';
import {
    buildLink,
    MarketId,
    Path,
    PathParams,
    QueryParamsSchemas
} from '../../routing';
import {
    CompletionParams,
    errorNotification,
    usePromise,
    useQueryParams
} from '../../utils';

const PaginationInitialPageIndex = 0;
const PaginationDefaultPageSize = 20;

const QueryParamsSchema = QueryParamsSchemas[Path.CategorySetList];

type Params = PathParams[Path.CategorySetList];

export const buildBreadcrumbsRoutes = (marketId: MarketId, intl: IntlShape) => {
    return [
        buildHomeBreadcrumbRoute(marketId),
        {
            path: buildLink(Path.CategorySetList, { marketId }),
            breadcrumbName: intl.formatMessage(
                Messages['common.label.category-sets']
            )
        }
    ];
};

export const CategorySetListPage = () => {
    const intl = useIntl();
    const categoriesData = useContext(CategoriesDataContext);
    const params = useParams<Params>();

    const { getLimits } = useUpsService();
    const { getCategorySets, removeCategorySets } = useProductCatalogService();

    const [queryParams, setQueryParams] = useQueryParams({
        schema: QueryParamsSchema,
        defaults: {
            page: PaginationInitialPageIndex,
            limit: PaginationDefaultPageSize
        }
    });

    const categorySetVariables = {
        ...queryParams,
        offset: queryParams.page * queryParams.limit
    };

    const categorySetsResult = usePromise({
        variables: categorySetVariables,
        promiseBuilder: async (variables) => {
            return getCategorySets(variables);
        }
    });

    const handleCategorySetsRemoveComplete = async (
        completionParams: CompletionParams<{ ids: CategorySetId[] }>
    ) => {
        if (completionParams.error) {
            void errorNotification({
                error: completionParams.error,
                message: intl.formatMessage(
                    Messages['common.error-message.remove-category-sets']
                )
            });

            return;
        }

        const removedProductsCount = completionParams.variables.ids.length;

        await categorySetsResult.execute(categorySetVariables);

        void message.success(
            intl.formatMessage(
                Messages['common.success-message.remove-category-sets'],
                {
                    count: removedProductsCount
                }
            )
        );
    };

    const limitsForCategorySestResult = usePromise({
        variables: undefined as unknown as GetLimitsInput,
        promiseBuilder: (defaultLimitForCategorySetQueryVariables) => {
            return getLimits(defaultLimitForCategorySetQueryVariables);
        },
        disableAutostart: true
    });

    const categorySetsRemove = usePromise({
        variables: undefined as unknown as RemoveCategorySetsInput,
        promiseBuilder: async (variables) => {
            return removeCategorySets(variables);
        },
        disableAutostart: true,
        onComplete: handleCategorySetsRemoveComplete
    });

    const filters = useMemo(() => {
        return {
            name: queryParams.name,
            types: queryParams.types,
            categoryIds: queryParams.categoryIds
        };
    }, [queryParams.name, queryParams.types, queryParams.categoryIds]);

    const categoriesLoading = categoriesData.loading && !categoriesData.data;
    const categorySetsLoading =
        categorySetsResult.loading && !categorySetsResult.data;

    if (categorySetsLoading || categoriesLoading) {
        return <Loader type="center" />;
    }

    const categorySets = categorySetsResult.data?.data;
    const categorySetsCount = categorySetsResult.data?.metadata?.totalCount;
    const categories = categoriesData.data;

    if (!categorySets || categorySetsCount === undefined || !categories) {
        return null;
    }

    const breadcrumbRoutes = buildBreadcrumbsRoutes(params.marketId, intl);

    const handlePageIndexChange = (page: number) => {
        setQueryParams({ page });
    };

    const handlePageSizeChange = (limit: number) => {
        const page = Math.floor((queryParams.page * queryParams.limit) / limit);
        setQueryParams({ page, limit });
    };

    const handleFiltersChange = (filters: Filters) => {
        setQueryParams({
            ...filters,
            page: 0
        });
    };

    const handleCategorySetsRemove = async (ids: CategorySetId[]) => {
        await limitsForCategorySestResult
            .execute({
                product_category_set_ids: ids,
                limit_type: LimitType.Default
            })
            .then(async (data) => {
                const foundLimits = data?.items;
                const foundLimitsCategorySetIDs = foundLimits?.map((limit) => {
                    return limit.product_category_set_id;
                });

                const allowedCategorySetIDs = ids.filter(
                    (id: string) => !foundLimitsCategorySetIDs?.includes(id)
                );

                const allIDsBlockedFromRemoval =
                    foundLimitsCategorySetIDs?.length === ids.length;

                if (foundLimitsCategorySetIDs?.length) {
                    const categorySetNamesListArray =
                        foundLimitsCategorySetIDs.map((limitCategorySetId) => {
                            return categorySets.find(
                                (categorySet) =>
                                    categorySet.id === limitCategorySetId
                            )?.name;
                        });

                    Modal.warning({
                        title: intl.formatMessage(
                            Messages['common.label.warning']
                        ),
                        okText: intl.formatMessage(
                            Messages['common.button.ok']
                        ),
                        closable: true,
                        content: (
                            <>
                                <p>
                                    {intl.formatMessage(
                                        Messages[
                                            'common.warning-message.unable-to-remove-category-sets'
                                        ]
                                    )}
                                </p>
                                <ul>
                                    {categorySetNamesListArray.map(
                                        (categorySetName, index) => (
                                            <li key={index}>
                                                {categorySetName}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </>
                        ),
                        onOk: async () => {
                            if (allIDsBlockedFromRemoval) {
                                Modal.destroyAll();
                            } else {
                                await categorySetsRemove.execute({
                                    ids: allowedCategorySetIDs
                                });
                            }
                        }
                    });
                } else {
                    await categorySetsRemove.execute({
                        ids: allowedCategorySetIDs
                    });
                }
            });
    };

    return (
        <CategorySetListLayout
            marketId={params.marketId}
            categorySets={categorySets}
            breadcrumbRoutes={breadcrumbRoutes}
            categorySetsCount={categorySetsCount}
            categorySetsLoading={categorySetsResult.loading}
            categories={categories}
            pageIndex={queryParams.page}
            pageSize={queryParams.limit}
            filters={filters}
            onPageIndexChange={handlePageIndexChange}
            onPageSizeChange={handlePageSizeChange}
            onFiltersChange={handleFiltersChange}
            onCategorySetsRemove={handleCategorySetsRemove}
        />
    );
};
