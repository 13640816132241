import { useContext } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { message } from 'antd';
import {
    CreateLimitInput,
    CreateLimitOutput,
    LimitId
} from '@olxeu-monetization/user-product-service-api-client';
import { Loader } from '../../components/Loader';
import {
    CategoriesDataContext,
    MarketLanguagesDataContext
} from '../../context';
import { getCategorySetNames, useProductCatalogService } from '../../helpers';
import { useUpsService } from '../../helpers';
import { Messages } from '../../intl';
import {
    LimitFormLayout,
    LimitFormPayload
} from '../../layouts/LimitFormLayout';
import { mapFormDataToFormValues } from '../../layouts/LimitFormLayout/helpers';
import { buildLink, MarketId, Path, PathParams } from '../../routing';
import {
    arrangeLimits,
    CompletionParams,
    errorNotification,
    usePromise
} from '../../utils';
import { buildBreadcrumbsRoutes as buildUpstreamBreadcrumbsRoutes } from '../LimitListPage';

type Params = PathParams[Path.LimitEdit];

const buildBreadcrumbsRoutes = (
    limitId: LimitId,
    marketId: MarketId,
    intl: IntlShape
) => {
    return [
        ...buildUpstreamBreadcrumbsRoutes(marketId, intl),
        {
            path: buildLink(Path.LimitDetails, { id: limitId, marketId }),
            breadcrumbName: intl.formatMessage(Messages['common.label.limit'])
        },
        {
            path: buildLink(Path.LimitEdit, { id: limitId, marketId }),
            breadcrumbName: intl.formatMessage(
                Messages['common.label.edit-limit']
            )
        }
    ];
};

export const LimitEditPage = () => {
    const intl = useIntl();
    const params = useParams<Params>();
    const navigate = useNavigate();
    const categoriesData = useContext(CategoriesDataContext);
    const marketLanguagesData = useContext(MarketLanguagesDataContext);

    const { getLimit, getLimits, createLimit } = useUpsService();
    const { getCategorySets } = useProductCatalogService();

    const breadcrumbRoutes = buildBreadcrumbsRoutes(
        params.id,
        params.marketId,
        intl
    );

    const handleLimitUpdateComplete = (
        completionParams: CompletionParams<unknown, CreateLimitOutput[]>
    ) => {
        if (completionParams.error) {
            void errorNotification({
                error: completionParams.error,
                message: intl.formatMessage(
                    Messages['common.error-message.limit-edit']
                )
            });

            return;
        }

        void message.success(
            intl.formatMessage(Messages['common.success-message.limit-edit'])
        );

        const limitsListLink = buildLink(Path.LimitList, {
            marketId: params.marketId
        });

        navigate(limitsListLink);
    };

    const limitUpdate = usePromise({
        variables: undefined as unknown as CreateLimitInput[],
        promiseBuilder: async (variables) => {
            return createLimit(variables);
        },
        disableAutostart: true,
        onComplete: handleLimitUpdateComplete
    });

    const handleSubmit = async (limit: LimitFormPayload[]) => {
        const updateData = limit.map((limit) =>
            Object.assign(limit, { id: params.id })
        );
        await limitUpdate.execute(updateData);
    };

    const limitVariables = {
        id: params.id
    };

    const defaultLimitResult = usePromise({
        variables: limitVariables,
        promiseBuilder: async (variables) => {
            return getLimit(variables);
        }
    });

    const allLimitsQueryVariables = {
        product_category_set_ids: [
            String(defaultLimitResult.data?.product_category_set_id)
        ]
    };

    const allLimitsResult = usePromise({
        variables: allLimitsQueryVariables,
        promiseBuilder: async (allLimitsQueryVariables) => {
            return getLimits(allLimitsQueryVariables);
        },
        disableAutostart: !defaultLimitResult.data
    });

    const limitLoading = allLimitsResult.loading && !allLimitsResult.data;

    const limit = defaultLimitResult.data;

    const categorySetNamesResult = usePromise({
        variables: {
            categorySetIds: String(limit?.product_category_set_id)
        },
        promiseBuilder: (variables) => {
            return getCategorySetNames({
                categorySetIds: [variables.categorySetIds],
                onCategorySetsGet: async (requestVariables) => {
                    return await getCategorySets(requestVariables);
                }
            });
        },
        disableAutostart: !limit
    });

    const categorySetNamesMap = categorySetNamesResult.data;
    const allLimits = arrangeLimits(allLimitsResult.data?.items);

    if (limitLoading || categorySetNamesResult.loading) {
        return <Loader type="center" />;
    }

    const categories = categoriesData.data;

    if (
        !allLimits ||
        !limit ||
        !categorySetNamesMap ||
        !categories ||
        !marketLanguagesData.data
    )
        return null;

    const initialValues = mapFormDataToFormValues(
        allLimits,
        categorySetNamesMap,
        marketLanguagesData.data.current
    );

    return (
        <LimitFormLayout
            limits={allLimits}
            title={intl.formatMessage(Messages['common.label.edit-limit'])}
            breadcrumbRoutes={breadcrumbRoutes}
            categories={categories}
            categorySetsTotalCount={initialValues.target.categorySets.length}
            submitting={limitUpdate.loading}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            marketId={params.marketId}
        />
    );
};
