import { ReactNode, useCallback, useContext, useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, useOktaAuth } from '@okta/okta-react';
import { AuthContext, ConfigContext } from '../../context';
import { MarketId, Path } from '../../routing';
import {
    getMarketsFromJwtRoles,
    getOktaMarketConfigByClientId,
    getOktaMarketConfigByMarketId,
    getOktaMarketIds,
    parseJwt
} from './helpers';
import { OktaJwt } from './types';

interface AuthContextProviderInternalProps {
    children?: ReactNode;
}

const AuthContextProviderInternal = ({
    children
}: AuthContextProviderInternalProps) => {
    const config = useContext(ConfigContext);
    const { authState, oktaAuth } = useOktaAuth();

    const isUsingSingleOktaClient = useMemo(() => {
        if (!authState?.idToken?.idToken) {
            return false;
        }

        const jwt = parseJwt<OktaJwt>(authState?.idToken?.idToken);

        return jwt.aud === config.services.oktaAuthService.clientId;
    }, [authState?.idToken?.idToken, config.services.oktaAuthService.clientId]);

    const authorizedMarketScopes = useMemo(() => {
        if (!authState?.idToken?.idToken) {
            return undefined;
        }

        const jwt = parseJwt<OktaJwt>(authState?.idToken?.idToken);

        return jwt.groups;
    }, [authState?.idToken?.idToken]);

    const authorizedMarketId = useMemo(() => {
        if (!authState?.idToken?.idToken) {
            return undefined;
        }

        const jwt = parseJwt<OktaJwt>(authState?.idToken?.idToken);

        if (jwt.aud === undefined) {
            throw new Error('User session contains no client id');
        }

        const marketConfig = getOktaMarketConfigByClientId(
            jwt.aud,
            config.services.oktaAuthService.marketConfigs
        );

        if (marketConfig === undefined) {
            throw new Error('Client id in user session is unsupported');
        }

        if (isUsingSingleOktaClient) {
            const marketIds = getMarketsFromJwtRoles(
                authorizedMarketScopes ?? [],
                MarketId
            );

            return marketIds[0];
        }

        return marketConfig.marketId;
    }, [
        authState?.idToken?.idToken,
        config.services.oktaAuthService.marketConfigs,
        isUsingSingleOktaClient,
        authorizedMarketScopes
    ]);

    const openAuthenticationPage = useCallback(
        (marketId: MarketId) => {
            const marketConfig = getOktaMarketConfigByMarketId(
                marketId,
                config.services.oktaAuthService.marketConfigs
            );

            if (marketConfig === undefined) {
                throw new Error('Requested market is unsupported');
            }

            void oktaAuth.signInWithRedirect({
                clientId: marketConfig.clientId
            });
        },
        [config.services.oktaAuthService, oktaAuth]
    );

    const context = useMemo(() => {
        return {
            authenticated: authState?.isAuthenticated ?? false,
            getAccessToken: () => Promise.resolve(authState?.idToken?.idToken),
            userMarketId: authorizedMarketId,

            userMarketScopes: authorizedMarketScopes,
            openAuthenticationPage,
            getUserInfo: () => oktaAuth.getUser(),
            logout: () => oktaAuth.signOut(),
            isUsingSingleOktaClient
        };
    }, [
        authState?.isAuthenticated,
        authState?.idToken?.idToken,
        authorizedMarketId,
        authorizedMarketScopes,
        openAuthenticationPage,
        isUsingSingleOktaClient,
        oktaAuth
    ]);

    // NOTE: empty `authState` means session is being initialized; after initialization, the component
    // will rerender with non-empty `authState`, where `isAuthenticated` can be either `true` or `false`;
    // in case if we land on login callback url, the `authState` will always be null; for this use case,
    // we allow our app to render routing and consume login callback codes.
    if (!authState && !oktaAuth.isLoginRedirect()) {
        return null;
    }

    return (
        <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
    );
};

interface AuthContextProviderProps {
    children?: ReactNode;
}

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
    const config = useContext(ConfigContext);
    const locationMatch = useMatch(`${Path.Market}/*`);
    const navigate = useNavigate();

    const clientId = useMemo(() => {
        const configMarketIds = getOktaMarketIds(
            config.services.oktaAuthService.marketConfigs
        );

        const marketId = configMarketIds.find((configMarketId) => {
            return configMarketId === locationMatch?.params.marketId;
        });

        if (marketId === undefined) {
            return;
        }

        const marketConfig = getOktaMarketConfigByMarketId(
            marketId,
            config.services.oktaAuthService.marketConfigs
        );

        return marketConfig?.clientId;
    }, [
        config.services.oktaAuthService.marketConfigs,
        locationMatch?.params.marketId
    ]);

    const oktaAuth = useMemo(() => {
        const { issuer, redirectUri, scopes, pkce } =
            config.services.oktaAuthService;

        return new OktaAuth({
            clientId,
            issuer,
            redirectUri,
            scopes,
            pkce
        });
    }, [clientId, config.services.oktaAuthService]);

    const restoreOriginalUri = (oktaAuth: OktaAuth, originalUri: string) => {
        const nextUrl = toRelativeUrl(
            originalUri || '/',
            window.location.origin
        );

        navigate(nextUrl, { replace: true });
    };

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <AuthContextProviderInternal>
                {children}
            </AuthContextProviderInternal>
        </Security>
    );
};
