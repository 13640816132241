import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
    cancelOfferChange,
    CancelOfferChangeInput,
    createCategorySet,
    CreateCategorySetInput,
    createOfferChange,
    CreateOfferChangeInput,
    createOfferChangeProduct,
    CreateOfferChangeProductInput,
    createTemplate,
    CreateTemplateInput,
    getAvailableLanguages,
    getCategories,
    getCategorySet,
    GetCategorySetInput,
    getCategorySets,
    GetCategorySetsInput,
    getCurrentMarketLanguages,
    getFeatures,
    GetFeaturesInput,
    getOfferChange,
    GetOfferChangeInput,
    getOfferChangeProduct,
    GetOfferChangeProductInput,
    getOfferChangeProducts,
    GetOfferChangeProductsInput,
    getOfferChanges,
    GetOfferChangesInput,
    getProduct,
    getProductAtVersion,
    GetProductAtVersionInput,
    GetProductInput,
    getProducts,
    GetProductsInput,
    getProductTypes,
    getTemplate,
    GetTemplateInput,
    getTemplates,
    GetTemplatesInput,
    MarketId,
    MarketLanguagesInput,
    publishOfferChange,
    PublishOfferChangeInput,
    removeCategorySets,
    RemoveCategorySetsInput,
    removeOfferChangeProducts,
    RemoveOfferChangeProductsInput,
    removeTemplate,
    RemoveTemplateInput,
    updateCategorySet,
    UpdateCategorySetInput,
    updateOfferChange,
    UpdateOfferChangeInput,
    updateOfferChangeProduct,
    UpdateOfferChangeProductInput,
    updateTemplate,
    UpdateTemplateInput,
    updateTemplateTranslation,
    UpdateTemplateTranslationInput
} from '@olxeu-monetization/product-catalog-api-client';
import { AuthContext, ConfigContext } from '../context';

type Params = {
    marketId?: MarketId;
};

export const useProductCatalogService = () => {
    const auth = useContext(AuthContext);
    const config = useContext(ConfigContext);
    const params = useParams<Params>();

    const buildContext = async () => {
        return {
            baseUrl: config.services.productCatalogService.baseUrl,
            accessToken: await auth.getAccessToken(),
            market: params.marketId
        };
    };

    return {
        getProducts: async (input: GetProductsInput) => {
            const context = await buildContext();
            return getProducts(input, context);
        },
        getProduct: async (id: GetProductInput) => {
            const context = await buildContext();
            return getProduct(id, context);
        },
        getProductAtVersion: async (id: GetProductAtVersionInput) => {
            const context = await buildContext();
            return getProductAtVersion(id, context);
        },
        getOfferChanges: async (input: GetOfferChangesInput) => {
            const context = await buildContext();
            return getOfferChanges(input, context);
        },
        getOfferChange: async (input: GetOfferChangeInput) => {
            const context = await buildContext();
            return getOfferChange(input, context);
        },
        createOfferChange: async (input: CreateOfferChangeInput) => {
            const context = await buildContext();
            return createOfferChange(input, context);
        },
        updateOfferChange: async (input: UpdateOfferChangeInput) => {
            const context = await buildContext();
            return updateOfferChange(input, context);
        },
        publishOfferChange: async (input: PublishOfferChangeInput) => {
            const context = await buildContext();
            return publishOfferChange(input, context);
        },
        cancelOfferChange: async (input: CancelOfferChangeInput) => {
            const context = await buildContext();
            return cancelOfferChange(input, context);
        },
        getOfferChangeProducts: async (input: GetOfferChangeProductsInput) => {
            const context = await buildContext();
            return getOfferChangeProducts(input, context);
        },
        getOfferChangeProduct: async (input: GetOfferChangeProductInput) => {
            const context = await buildContext();
            return getOfferChangeProduct(input, context);
        },
        createOfferChangeProduct: async (
            input: CreateOfferChangeProductInput
        ) => {
            const context = await buildContext();
            return createOfferChangeProduct(input, context);
        },
        updateOfferChangeProduct: async (
            input: UpdateOfferChangeProductInput
        ) => {
            const context = await buildContext();
            return updateOfferChangeProduct(input, context);
        },
        removeOfferChangeProducts: async (
            input: RemoveOfferChangeProductsInput
        ) => {
            const context = await buildContext();
            return removeOfferChangeProducts(input, context);
        },
        getFeatures: async (input: GetFeaturesInput) => {
            const context = await buildContext();
            return getFeatures(input, context);
        },
        getProductTypes: async () => {
            const context = await buildContext();
            return getProductTypes(context);
        },
        getCategories: async () => {
            const context = await buildContext();
            return getCategories(context);
        },
        getCategorySets: async (input: GetCategorySetsInput) => {
            const context = await buildContext();
            return getCategorySets(input, context);
        },
        getCategorySet: async (input: GetCategorySetInput) => {
            const context = await buildContext();
            return getCategorySet(input, context);
        },
        createCategorySet: async (input: CreateCategorySetInput) => {
            const context = await buildContext();
            return createCategorySet(input, context);
        },
        updateCategorySet: async (input: UpdateCategorySetInput) => {
            const context = await buildContext();
            return updateCategorySet(input, context);
        },
        removeCategorySets: async (input: RemoveCategorySetsInput) => {
            const context = await buildContext();
            return removeCategorySets(input, context);
        },
        getTemplates: async (input: GetTemplatesInput) => {
            const context = await buildContext();
            return getTemplates(input, context);
        },
        getTemplate: async (input: GetTemplateInput) => {
            const context = await buildContext();
            return getTemplate(input, context);
        },
        createTemplate: async (input: CreateTemplateInput) => {
            const context = await buildContext();
            return createTemplate(input, context);
        },
        updateTemplate: async (input: UpdateTemplateInput) => {
            const context = await buildContext();
            return updateTemplate(input, context);
        },
        updateTemplateTranslation: async (
            input: UpdateTemplateTranslationInput
        ) => {
            const context = await buildContext();
            return updateTemplateTranslation(input, context);
        },
        removeTemplate: async (input: RemoveTemplateInput) => {
            const context = await buildContext();
            return removeTemplate(input, context);
        },
        getAvailableLanguages: async () => {
            const context = await buildContext();
            return getAvailableLanguages(context);
        },
        getCurrentMarketLanguages: async (input: MarketLanguagesInput) => {
            const context = await buildContext();
            return getCurrentMarketLanguages(input, context);
        }
    };
};
