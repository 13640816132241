import { useContext } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { message, notification, Space, Typography } from 'antd';
import {
    HTTPError,
    TemplateId,
    UpdateTemplateInput,
    UpdateTemplateTranslationInput
} from '@olxeu-monetization/product-catalog-api-client';
import { Loader } from '../../components/Loader';
import { MarketLanguagesDataContext } from '../../context';
import { useProductCatalogService } from '../../helpers';
import { Messages } from '../../intl';
import { TemplateFormLayout } from '../../layouts/TemplateFormLayout';
import { mapFormDataToFormValues } from '../../layouts/TemplateFormLayout/helpers';
import { TemplateFormPayload } from '../../layouts/TemplateFormLayout/types';
import { buildLink, MarketId, Path, PathParams } from '../../routing';
import { errorNotification, usePromise } from '../../utils';
import { buildBreadcrumbsRoutes as buildUpstreamBreadcrumbsRoutes } from '../TemplateListPage';

const { Text } = Typography;

const EDITABLE_PROPERTIES = ['name', 'description'] as const;

type Params = PathParams[Path.TemplateEdit];

const buildBreadcrumbsRoutes = (
    templateId: TemplateId,
    marketId: MarketId,
    intl: IntlShape
) => {
    return [
        ...buildUpstreamBreadcrumbsRoutes(marketId, intl),
        {
            path: buildLink(Path.TemplateEdit, {
                id: templateId,
                marketId
            }),
            breadcrumbName: intl.formatMessage(
                Messages['common.label.edit-template']
            )
        }
    ];
};

export const TemplateEditPage = () => {
    const intl = useIntl();
    const marketLanguagesData = useContext(MarketLanguagesDataContext);
    const navigate = useNavigate();
    const params = useParams<Params>();

    const { getTemplate, updateTemplate, updateTemplateTranslation } =
        useProductCatalogService();

    const templateResult = usePromise({
        variables: {
            id: params.id
        },
        promiseBuilder: async (variables) => {
            return getTemplate(variables);
        }
    });

    const templateUpdate = usePromise({
        variables: undefined as unknown as UpdateTemplateInput,
        promiseBuilder: async (variables) => {
            return updateTemplate(variables);
        },
        disableAutostart: true
    });

    const templateTranslationUpdate = usePromise({
        variables: undefined as unknown as UpdateTemplateTranslationInput,
        promiseBuilder: async (variables) => {
            return updateTemplateTranslation(variables);
        },
        disableAutostart: true
    });

    if (marketLanguagesData.loading || templateResult.loading) {
        return <Loader type="center" />;
    }

    const languages = marketLanguagesData.data;
    const template = templateResult.data?.data;

    if (!languages || !template) {
        return null;
    }

    const breadcrumbRoutes = buildBreadcrumbsRoutes(
        params.id,
        params.marketId,
        intl
    );

    const handleSubmit = async (editedTemplate: TemplateFormPayload) => {
        const updatedProperties = EDITABLE_PROPERTIES.filter((property) => {
            const param = property as keyof typeof template;
            return template[param] !== editedTemplate[param];
        });

        const promises = [];

        if (updatedProperties.length) {
            const templateProperties =
                updatedProperties.reduce((acc, property) => {
                    acc[property] = editedTemplate[property];
                    return acc;
                }, {} as Partial<UpdateTemplateInput>) ?? {};

            const input = {
                id: params.id,
                ...templateProperties
            };

            promises.push(templateUpdate.execute(input));
        }

        const definedTranslations = template.translations.filter(
            (translation) => translation.value !== undefined
        );

        const newTranslations = editedTemplate.translations.filter(
            (translation) =>
                translation.value !== undefined &&
                !definedTranslations.some(
                    (definedTranslation) =>
                        definedTranslation.language === translation.language
                )
        );

        newTranslations.forEach((translation) => {
            const input = {
                id: params.id,
                ...translation
            };

            promises.push(templateTranslationUpdate.execute(input));
        });

        if (promises.length === 0) {
            void message.warning(
                intl.formatMessage(
                    Messages['common.warning-message.template-edit-empty']
                )
            );
            return;
        }

        const results = await Promise.allSettled(promises);

        const fulfilled = results.filter(
            (result) => result.status === 'fulfilled'
        );

        const rejected = results.filter(
            (result) => result.status === 'rejected'
        ) as PromiseRejectedResult[];

        const successAll = fulfilled.length === promises.length;

        if (successAll) {
            void message.success(
                intl.formatMessage(
                    Messages['common.success-message.template-edit']
                )
            );
        } else if (fulfilled.length > 0) {
            const errors: string[] = [];

            rejected.forEach((item) => {
                const error = item.reason as HTTPError;

                if (error.details) {
                    errors.push(error.details);
                }
            });

            void notification['warning']({
                message: intl.formatMessage(
                    Messages['common.warning-message.template-edit']
                ),
                placement: 'top',
                duration: 0,
                description: (
                    <Space direction="vertical" size={4}>
                        {errors.map((error) => (
                            <Text type="danger">{error}</Text>
                        ))}
                    </Space>
                )
            });
        }

        if (rejected.length === promises.length) {
            const errors = rejected.map((item) => item.reason as HTTPError);

            errors.forEach((error) => {
                void errorNotification({
                    error,
                    message: intl.formatMessage(
                        Messages['common.error-message.template-edit']
                    )
                });
            });

            return;
        }

        const detailsLink = buildLink(Path.TemplateDetails, {
            id: params.id,
            marketId: params.marketId
        });

        navigate(detailsLink);
    };

    const initialValues = mapFormDataToFormValues(template);

    return (
        <TemplateFormLayout
            title={intl.formatMessage(Messages['common.label.edit-template'])}
            breadcrumbRoutes={breadcrumbRoutes}
            languages={languages.available}
            defaultLanguage={languages.default}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            submitDisabled={
                templateUpdate.loading || templateTranslationUpdate.loading
            }
        />
    );
};
