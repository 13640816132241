import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
    Alert,
    AugmentedRoute,
    Button,
    Dropdown,
    Form,
    MenuProps,
    Space,
    Table,
    Tag
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { FieldData } from 'rc-field-form/lib/interface';
import styled, { useTheme } from 'styled-components';
import { Category } from '@olxeu-monetization/product-catalog-api-client';
import {
    Limit,
    LimitStatus,
    LimitType
} from '@olxeu-monetization/user-product-service-api-client';
import { BottomBar } from '../../components/BottomBar';
import { CategoriesSection } from '../../components/CategorySetSections/CategoriesSection';
import { PageHeader } from '../../components/PageHeader';
import { Section } from '../../components/Section';
import { useUpsService } from '../../helpers';
import { Messages } from '../../intl';
import { buildLink, MarketId, Path } from '../../routing';
import { usePromise } from '../../utils';
import {
    buildFormTableColumns,
    getTableData
} from '../LimitDetailsLayout/utils';
import { LimitTableDataType } from '../LimitDetailsLayout/utils';
import { CategorySetsFormSection } from './CategorySetsFormSection/CategorySetsFormSection';
import { mapFormValuesToFormPayload } from './helpers';
import { LimitFormPayload, LimitFormValues, limitTypesSet } from './types';

interface Props {
    limits?: Limit[];
    title: string;
    breadcrumbRoutes: AugmentedRoute[];
    categories: Category[];
    categorySetsTotalCount: number;
    submitting: boolean;
    onSubmit?: (limit: LimitFormPayload[]) => void;
    initialValues?: LimitFormValues;
    marketId: MarketId;
}

const EditButton = styled(Button)`
    ${({ theme }) => `
            margin-left: ${theme.space[12]};
    `}
`;

export const LimitFormLayout = ({
    limits,
    title,
    breadcrumbRoutes,
    categories,
    categorySetsTotalCount,
    submitting,
    onSubmit,
    initialValues,
    marketId
}: Props) => {
    const isEdit = initialValues !== undefined;
    const intl = useIntl();
    const theme = useTheme();
    const { formatMessage } = intl;
    const [form] = Form.useForm();
    const [allFieldsData, setAllFieldsData] = useState<FieldData[]>([]);
    const { getLimits } = useUpsService();
    const [hasErrors, setHasErrors] = useState(!isEdit);
    const [limitTypesTableData, setLimitTypesTableData] = useState<
        LimitTableDataType[]
    >(
        isEdit && limits
            ? getTableData(limits)
            : [
                  {
                      key: 0,
                      type: LimitType.Default,
                      validity: '',
                      capacity: 0,
                      unlimited: false,
                      status: LimitStatus.Disabled
                  }
              ]
    );

    const formFieldsData = form.getFieldsValue() as LimitFormValues;
    const selectedCategorySet = formFieldsData.target?.categorySets?.[0];
    const isDefaultLimitDefined =
        formFieldsData.default?.unlimited ||
        (formFieldsData.default?.capacity >= 0 &&
            Boolean(formFieldsData.default?.validity));

    if (!selectedCategorySet && !isEdit) form.resetFields();

    const handleFieldsChanged = (
        changedFields: FieldData[],
        allFields: FieldData[]
    ) => {
        if (
            changedFields[0].name.toString() ===
                `${LimitType.Default},status` &&
            changedFields[0].value === false
        ) {
            limitTypesSet.forEach((limitType) => {
                form.setFieldValue([limitType, 'status'], undefined);
            });
        }
        setAllFieldsData(allFields);
    };

    const handleSubmit = (values: LimitFormValues) => {
        const limitPayload = mapFormValuesToFormPayload(
            values,
            initialValues?.target.categorySets[0]
        );
        onSubmit?.(limitPayload);
    };

    const removeLimitType = (limitType: LimitType) => {
        setLimitTypesTableData([
            ...limitTypesTableData.filter(
                (limitTypeData) => limitTypeData.type !== limitType
            )
        ]);
        setHasErrors(false);
    };

    const columns = buildFormTableColumns(
        intl,
        isDefaultLimitDefined,
        removeLimitType,
        formFieldsData
    );

    const addLimitType = (type: LimitType) => {
        setLimitTypesTableData([
            ...limitTypesTableData,
            ...[
                {
                    key: limitTypesTableData.length + 1,
                    type: type,
                    validity: '',
                    capacity: 0,
                    unlimited: false,
                    status: LimitStatus.Disabled
                }
            ]
        ]);
    };

    const getAvailableMenuItems = (
        limitTypesTableData: LimitTableDataType[]
    ) => {
        return [
            ...[
                !limitTypesTableData.some(
                    (limitType) => limitType.type === LimitType.Business
                ) && {
                    label: formatMessage(Messages['common.label.business']),
                    key: '0',
                    onClick: () => addLimitType(LimitType.Business)
                }
            ],
            ...[
                !limitTypesTableData.some(
                    (limitType) => limitType.type === LimitType.Extra
                ) && {
                    label: formatMessage(Messages['common.label.extra']),
                    key: '1',
                    onClick: () => addLimitType(LimitType.Extra)
                }
            ]
        ];
    };

    const addLimitMenuProps = {
        items: getAvailableMenuItems(limitTypesTableData) as MenuProps['items']
    };

    useEffect(() => {
        limitTypesSet.forEach((limitType) => {
            if (formFieldsData[limitType]?.unlimited) {
                form.setFieldValue([limitType, 'capacity'], undefined);
                form.setFieldValue([limitType, 'validity'], undefined);

                if (form.getFieldError([limitType, 'capacity']).length)
                    form.resetFields([[limitType, 'capacity']]);

                if (form.getFieldError([limitType, 'validity']).length)
                    form.resetFields([[limitType, 'validity']]);
            }
        });

        const nextHasErrors =
            allFieldsData.some((field) => field.errors?.length) ||
            !Boolean(selectedCategorySet || isEdit) ||
            !isDefaultLimitDefined;

        setHasErrors(nextHasErrors);
    }, [
        allFieldsData,
        limitTypesTableData,
        form,
        formFieldsData,
        isDefaultLimitDefined,
        isEdit,
        selectedCategorySet
    ]);

    const defaultLimitForCategorySetQueryVariables = {
        product_category_set_ids: [selectedCategorySet?.id],
        limit_type: LimitType.Default
    };

    const defaultLimitForCategorySetResult = usePromise({
        variables: defaultLimitForCategorySetQueryVariables,
        promiseBuilder: (defaultLimitForCategorySetQueryVariables) => {
            return getLimits(defaultLimitForCategorySetQueryVariables);
        },
        disableAutostart: !Boolean(selectedCategorySet) || isEdit
    });

    const defaultConfiguredLimitForSelectedCategorySet =
        defaultLimitForCategorySetResult.data?.items[0];

    const isAllLimitTypesDefined =
        limitTypesTableData.length === limitTypesSet.length;

    const showForm =
        (selectedCategorySet &&
            !defaultLimitForCategorySetResult.loading &&
            !defaultConfiguredLimitForSelectedCategorySet) ||
        isEdit;

    const showDefaultLimitExistsMessage =
        !isEdit &&
        Boolean(defaultConfiguredLimitForSelectedCategorySet) &&
        selectedCategorySet;

    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            onFieldsChange={handleFieldsChanged}
            initialValues={initialValues}
            layout="vertical"
            scrollToFirstError={true}
            data-testid="limit-form-layout"
            className="form-with-enhanced-labels"
        >
            <PageHeader
                title={title}
                tags={
                    isEdit ? (
                        <Tag color={theme.colors.tagIdentifier}>
                            {formatMessage(Messages['common.tag.id'], {
                                id: initialValues.default?.id
                            })}
                        </Tag>
                    ) : undefined
                }
                breadcrumbRoutes={breadcrumbRoutes}
            />

            {isEdit ? (
                <>
                    <Section
                        title={formatMessage(
                            Messages['common.label.category-set']
                        )}
                    >
                        {initialValues.target.categorySets[0].name}
                        <Link
                            to={buildLink(Path.CategorySetEdit, {
                                id: initialValues.target.categorySets[0].id,
                                marketId
                            })}
                        >
                            <EditButton size="small">
                                {formatMessage(Messages['common.button.edit'])}
                            </EditButton>
                        </Link>
                    </Section>
                    <CategoriesSection
                        categories={categories}
                        categoryIds={
                            initialValues.target.categorySets[0].categoryIds
                        }
                    />
                </>
            ) : (
                <CategorySetsFormSection
                    categories={categories}
                    categorySetsTotalCount={categorySetsTotalCount}
                />
            )}
            {showDefaultLimitExistsMessage &&
                defaultConfiguredLimitForSelectedCategorySet && (
                    <Section>
                        <Alert
                            data-testid="limit-already-exists-warning"
                            message={formatMessage(
                                Messages[
                                    'common.warning-message.limit-already-exists'
                                ],
                                {
                                    b: (chunks: string) => <b>{chunks}</b>,
                                    categorySetName: selectedCategorySet.name
                                }
                            )}
                            type="warning"
                            action={
                                <Link
                                    to={buildLink(Path.LimitEdit, {
                                        id: defaultConfiguredLimitForSelectedCategorySet.id,
                                        marketId: marketId
                                    })}
                                >
                                    {formatMessage(
                                        Messages['common.button.edit-limit']
                                    )}
                                </Link>
                            }
                            showIcon
                        />
                    </Section>
                )}

            {showForm && (
                <Section
                    title={formatMessage(
                        Messages['common.label.limit-properties']
                    )}
                    data-testid="limits-properties-form-section"
                >
                    <Table
                        data-testid="limit-types-properties-table"
                        dataSource={limitTypesTableData}
                        columns={columns}
                        pagination={false}
                        footer={() => (
                            <Dropdown
                                disabled={
                                    !isDefaultLimitDefined ||
                                    isAllLimitTypesDefined
                                }
                                menu={addLimitMenuProps}
                            >
                                <Button data-testid="add-limit-type-button">
                                    <Space>
                                        {formatMessage(
                                            Messages[
                                                'common.button.add-limit-type'
                                            ]
                                        )}
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        )}
                    />
                </Section>
            )}

            <BottomBar>
                <Form.Item noStyle={true}>
                    <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        disabled={submitting || hasErrors}
                    >
                        {formatMessage(Messages['common.button.save'])}
                    </Button>
                </Form.Item>
            </BottomBar>
        </Form>
    );
};
