import { useContext } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, message, Tooltip } from 'antd';
import {
    HTTPError,
    HTTPResponseStatus,
    OfferChangeId,
    ResultEnvelope,
    UpdateOfferChangeProductInput,
    UpdateOfferChangeProductOutput
} from '@olxeu-monetization/product-catalog-api-client';
import { Loader } from '../../components/Loader';
import {
    CategoriesDataContext,
    FeaturesDataContext,
    MarketLanguagesDataContext,
    ProductTypesDataContext
} from '../../context';
import { getCategorySetNames, useProductCatalogService } from '../../helpers';
import { Messages } from '../../intl';
import {
    ProductFormLayout,
    ProductFormPayload
} from '../../layouts/ProductFormLayout';
import { buildLink, MarketId, Path, PathParams } from '../../routing';
import { CompletionParams, errorNotification, usePromise } from '../../utils';
import { buildBreadcrumbsRoutes as buildUpstreamBreadcrumbsRoutes } from '../OfferChangeDetailsPage';

type Params = PathParams[Path.OfferChangeProductEdit];

const buildBreadcrumbsRoutes = (
    offerChangeId: OfferChangeId,
    marketId: MarketId,
    intl: IntlShape
) => {
    return [
        ...buildUpstreamBreadcrumbsRoutes(offerChangeId, marketId, intl),
        {
            path: buildLink(Path.OfferChangeProductCreate, {
                id: offerChangeId,
                marketId
            }),
            breadcrumbName: intl.formatMessage(
                Messages['common.label.edit-product']
            )
        }
    ];
};

export const OfferChangeProductEditPage = () => {
    const intl = useIntl();
    const marketLanguagesData = useContext(MarketLanguagesDataContext);
    const productTypesData = useContext(ProductTypesDataContext);
    const featuresData = useContext(FeaturesDataContext);
    const params = useParams<Params>();
    const categoriesData = useContext(CategoriesDataContext);
    const navigate = useNavigate();

    const {
        getProduct,
        getOfferChange,
        getOfferChangeProduct,
        getCategorySets,
        updateOfferChangeProduct
    } = useProductCatalogService();

    const handleProductEditComplete = (
        completionParams: CompletionParams<
            unknown,
            ResultEnvelope<UpdateOfferChangeProductOutput, unknown>
        >
    ) => {
        if (completionParams.error) {
            void errorNotification({
                error: completionParams.error,
                message: intl.formatMessage(
                    Messages['common.error-message.product-edit']
                )
            });

            return;
        }

        void message.success(
            intl.formatMessage(Messages['common.success-message.product-edit'])
        );

        const detailsLink = buildLink(Path.OfferChangeDetails, {
            id: params.id,
            marketId: params.marketId
        });

        navigate(detailsLink);
    };

    const offerChangeResult = usePromise({
        variables: {
            id: params.id
        },
        promiseBuilder: async (variables) => {
            return getOfferChange(variables);
        }
    });

    const productResult = usePromise({
        variables: {
            id: params.id,
            productId: params.productId
        },
        promiseBuilder: async (variables) => {
            return getOfferChangeProduct(variables).catch(async (error) => {
                if (
                    !(error instanceof HTTPError) ||
                    error.status !== HTTPResponseStatus.NotFound
                ) {
                    throw error;
                }

                return getProduct({ id: variables.productId });
            });
        }
    });

    const categorySetsResult = usePromise({
        variables: {
            offset: 0,
            limit: 0
        },
        promiseBuilder: async (variables) => {
            return getCategorySets(variables);
        }
    });

    const categorySetNamesResult = usePromise({
        variables: {
            categorySetIds: productResult.data?.data.target.categorySetIds
        },
        promiseBuilder: (variables) => {
            return getCategorySetNames({
                categorySetIds: variables.categorySetIds,
                onCategorySetsGet: async (requestVariables) => {
                    return await getCategorySets(requestVariables);
                }
            });
        },
        disableAutostart: !productResult.data
    });

    const productEdit = usePromise({
        variables: undefined as unknown as UpdateOfferChangeProductInput,
        promiseBuilder: async (variables) => {
            return updateOfferChangeProduct(variables);
        },
        disableAutostart: true,
        onComplete: handleProductEditComplete
    });

    const marketLanguagesLoading =
        marketLanguagesData.loading && !marketLanguagesData.data;
    const productTypesLoading =
        productTypesData.loading && !productTypesData.data;
    const featuresLoading = featuresData.loading && !featuresData.data;
    const productLoading = productResult.loading && !productResult.data;
    const offerChangeLoading =
        offerChangeResult.loading && !offerChangeResult.data;
    const categorySetsLoading = categorySetsResult.loading;
    const categorySetNamesLoading = categorySetNamesResult.loading;
    const categoriesLoading = categoriesData.loading && !categoriesData.data;

    const loading =
        marketLanguagesLoading ||
        productTypesLoading ||
        featuresLoading ||
        productLoading ||
        offerChangeLoading ||
        categorySetsLoading ||
        categorySetNamesLoading ||
        categoriesLoading;

    if (loading) {
        return <Loader type="center" />;
    }

    const marketLanguages = marketLanguagesData.data?.current;
    const productTypes = productTypesData.data;
    const features = featuresData.data;
    const featurePropertyValidationMap =
        featuresData.featurePropertyValidationMap;
    const product = productResult.data?.data;
    const offerChange = offerChangeResult?.data?.data;
    const categorySetsTotalCount = categorySetsResult.data?.metadata.totalCount;
    const categorySetNamesMap = categorySetNamesResult.data;
    const categories = categoriesData.data;

    if (
        !marketLanguages ||
        !productTypes ||
        !features ||
        !featurePropertyValidationMap ||
        !product ||
        !offerChange ||
        categorySetsTotalCount === undefined ||
        !categorySetNamesMap ||
        !categories
    ) {
        return null;
    }

    const breadcrumbRoutes = buildBreadcrumbsRoutes(
        params.id,
        params.marketId,
        intl
    );

    const handleSubmit = (product: ProductFormPayload) => {
        const input = {
            ...product,
            id: params.id,
            productId: params.productId,
            expectedVersion: offerChange.version
        };

        void productEdit.execute(input);
    };

    return (
        <ProductFormLayout
            title={intl.formatMessage(Messages['common.label.edit-product'])}
            product={product}
            features={features}
            featurePropertyValidationMap={featurePropertyValidationMap}
            breadcrumbRoutes={breadcrumbRoutes}
            productTypes={productTypes}
            marketLanguages={marketLanguages}
            categories={categories}
            categorySetNamesMap={categorySetNamesMap}
            categorySetsTotalCount={categorySetsTotalCount}
            renderSubmitButton={(hasErrors, hasUndefinedTranslation) => (
                <Tooltip
                    title={intl.formatMessage(
                        Messages[
                            'common.error-message.label-translation-undefined'
                        ]
                    )}
                    placement="left"
                    open={hasUndefinedTranslation}
                >
                    <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        disabled={
                            productEdit.loading ||
                            hasErrors ||
                            hasUndefinedTranslation
                        }
                    >
                        {intl.formatMessage(Messages['common.button.save'])}
                    </Button>
                </Tooltip>
            )}
            onSubmit={handleSubmit}
        />
    );
};
