import { Buffer } from 'buffer';
import { MarketId } from '../../routing';
import {
    OktaAccessRoleGrant,
    OktaAccessScope,
    OktaAccessScopeGrant,
    OktaMarketClientId,
    OktaMarketConfig
} from './types';

const rolesToScopeMap = new Map<OktaAccessRoleGrant, OktaAccessScopeGrant[]>([
    [
        OktaAccessRoleGrant.MonetizationAdmin,
        [
            OktaAccessScopeGrant.ProductCatalogRead,
            OktaAccessScopeGrant.ProductCatalogWrite
        ]
    ],
    [
        OktaAccessRoleGrant.MonetizationReader,
        [OktaAccessScopeGrant.ProductCatalogRead]
    ]
]);

export const isScopeEligibleForRole = (
    role: OktaAccessRoleGrant,
    scope: OktaAccessScopeGrant
) => {
    // TODO: OKTA - implement fetching roles from lambda and replace rolesToScopeMap
    const roleGrants = rolesToScopeMap.get(role);

    if (roleGrants === undefined) {
        return false;
    }

    return roleGrants.includes(scope);
};

export const parseJwt = <T = Record<string, unknown>>(encodedJwt: string) => {
    const segments = encodedJwt.split('.');
    const dataSegment = segments[1];
    const decodedData = Buffer.from(dataSegment, 'base64').toString('utf8');

    return JSON.parse(decodedData) as T;
};

export const getOktaMarketConfigByMarketId = (
    marketId: MarketId,
    marketConfigs: OktaMarketConfig[]
) => {
    return marketConfigs.find((marketConfig) => {
        return marketConfig.marketId === marketId;
    });
};

export const getOktaMarketConfigByClientId = (
    clientId: OktaMarketClientId,
    marketConfigs: OktaMarketConfig[]
) => {
    return marketConfigs.find((marketConfig) => {
        return marketConfig.clientId === clientId;
    });
};

export const getOktaMarketIds = (marketConfigs: OktaMarketConfig[]) => {
    return marketConfigs.map((marketConfig) => {
        return marketConfig.marketId;
    });
};

export const getMarketsFromJwtRoles = (
    roles: OktaAccessScope[],
    marketCandidates: typeof MarketId
): MarketId[] => {
    const pattern = /app_mh_(?:stg|prd)_([a-z]+)_monetization/;

    return roles
        .map((item) => {
            const match = item.match(pattern);

            if (match && match[1]) {
                return Object.values(marketCandidates).find(
                    (value) => value === match[1]
                );
            }

            return undefined;
        })
        .filter((item): item is MarketId => item !== undefined);
};

export const buildOktaMarketConfigs = (envSeed: string): OktaMarketConfig[] => {
    const markets = envSeed.split(';');

    const clientIds = markets.map((market) => {
        const [marketIdCandidate, clientId] = market.split('=');
        const marketId = parseMarketId(marketIdCandidate);

        if (marketId === undefined) {
            throw new Error('Market in config is unsupported');
        }

        return { marketId, clientId };
    });

    return clientIds;
};

export const parseAccessScopeGrant = (accessScope: OktaAccessScope) => {
    const grants = Object.values(OktaAccessScopeGrant);
    const grantsUnion = grants.join('|');

    const parser = new RegExp(
        `(?:app_monetization_backoffice|app_mh)_[^_]+_[^_]+_(${grantsUnion})`
    );
    const regexpResults = parser.exec(accessScope) as
        | [unknown, OktaAccessScopeGrant | undefined]
        | null;
    const grant = regexpResults?.[1];

    return grant;
};

export const parseAccessRoleGrant = (accessScope: OktaAccessScope) => {
    const grants = Object.values(OktaAccessRoleGrant);
    const grantsUnion = grants.join('|');

    const parser = new RegExp(`(?:app_mh)_[^_]+_[^_]+_(${grantsUnion})`);
    const regexpResults = parser.exec(accessScope) as
        | [unknown, OktaAccessRoleGrant | undefined]
        | null;
    const grant = regexpResults?.[1];

    return grant;
};

export const parseMarketId = (marketIdCandidate: string) => {
    const markets = Object.values(MarketId);
    return markets.find((marketId) => {
        return marketId === marketIdCandidate;
    });
};
