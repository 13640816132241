import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { List, Typography } from 'antd';
import {
    LabelMetadataValue,
    LabelValue,
    MarketLanguage,
    ProductFeature,
    TemplateId
} from '@olxeu-monetization/product-catalog-api-client';
import { useProductCatalogService } from '../../../helpers';
import { buildCombinedLabelValues } from '../../../layouts/ProductFormLayout/LabelsFormSection/utils';
import { usePromise } from '../../../utils';
import { Box } from '../../Box';
import { Loader } from '../../Loader';
import { ProductPropertyDiff } from '../ProductProperties/ProductPropertyDiff';
import { LabelAttributesTable } from './LabelAttributesTable';
import { LabelMetadataValueType } from './LabelAttributesTable/columns';
import { LabelValuesTable } from './LabelValuesTable';

const { Text } = Typography;

const getDisplayedValue = (value: string | null | undefined) => {
    if (!value) {
        return (
            <Text code>
                <FormattedMessage id="common.label.none" />
            </Text>
        );
    }

    return value;
};

interface ProductLabelProperty {
    id: string;
    label: string;
    value?: string;
    previousValue?: string;
}

interface ProductLabelValueItem {
    key: string;
    value: LabelValue;
    previousValue?: LabelValue;
    diffVisible?: boolean;
}

interface ProductLabelMetadataItem {
    key: string;
    value: LabelMetadataValue;
    previousValue?: LabelMetadataValue;
    type: LabelMetadataValueType;
    diffVisible?: boolean;
}

interface Props {
    templateId: TemplateId;
    marketLanguages: MarketLanguage[];
    staticProperties: ProductLabelProperty[];
    valuesSource: ProductLabelValueItem[];
    features: ProductFeature[];
    metadataSource: ProductLabelMetadataItem[];
    diffVisible?: boolean;
}

export const ProductLabelTabContent = ({
    templateId,
    marketLanguages,
    staticProperties,
    valuesSource,
    features,
    metadataSource,
    diffVisible
}: Props) => {
    const { getTemplate } = useProductCatalogService();

    const templateResult = usePromise({
        variables: {
            id: templateId
        },
        promiseBuilder: async (variables) => {
            return getTemplate(variables);
        }
    });

    const referencedFeature = staticProperties.find(
        (prop) => prop.id === 'featureId'
    )?.value;

    const labelValues = valuesSource.map((item) => ({
        name: item.key,
        value: item.value
    }));

    const combinedLabelValues = useMemo(() => {
        const referencedFeatureValues =
            features.find((feature) => feature.id === referencedFeature)
                ?.properties ?? [];

        return buildCombinedLabelValues(referencedFeatureValues, labelValues);
    }, [features, labelValues, referencedFeature]);

    if (templateResult.loading) return <Loader type="center" />;

    const template = templateResult.data?.data;

    if (!template) return null;

    const labelTranslations = template.translations;

    return (
        <>
            <Box maxWidth={600} mb={24}>
                <List
                    dataSource={staticProperties}
                    renderItem={(item) => {
                        const itemValue = getDisplayedValue(item.value);
                        const itemPreviousValue = getDisplayedValue(
                            item.previousValue
                        );

                        return (
                            <List.Item>
                                <List.Item.Meta
                                    title={item.label}
                                    description={
                                        diffVisible &&
                                        item.value !== undefined &&
                                        item.previousValue !== undefined ? (
                                            <ProductPropertyDiff
                                                id={item.id}
                                                value={itemValue}
                                                previousValue={
                                                    itemPreviousValue
                                                }
                                            />
                                        ) : (
                                            <span
                                                data-testid={`product-diff-properties-current-value[${item.id}]`}
                                            >
                                                {itemValue}
                                            </span>
                                        )
                                    }
                                />
                            </List.Item>
                        );
                    }}
                />
            </Box>
            <Box maxWidth={600} mb={24}>
                <LabelValuesTable
                    dataSource={valuesSource}
                    marketLanguages={marketLanguages}
                    labelValues={combinedLabelValues}
                    translations={labelTranslations}
                />
            </Box>
            <Box maxWidth={600} mb={24}>
                <LabelAttributesTable dataSource={metadataSource} />
            </Box>
        </>
    );
};
