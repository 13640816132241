import { useContext } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { message } from 'antd';
import {
    CreateCategorySetInput,
    CreateCategorySetOutput,
    ResultEnvelope
} from '@olxeu-monetization/product-catalog-api-client';
import { Loader } from '../../components/Loader';
import {
    CategoriesDataContext,
    MarketLanguagesDataContext
} from '../../context';
import { useProductCatalogService } from '../../helpers';
import { Messages } from '../../intl';
import {
    CategorySetFormLayout,
    CategorySetFormPayload
} from '../../layouts/CategorySetFormLayout';
import { buildLink, MarketId, Path, PathParams } from '../../routing';
import { CompletionParams, errorNotification, usePromise } from '../../utils';
import { buildBreadcrumbsRoutes as buildUpstreamBreadcrumbsRoutes } from '../CategorySetListPage';

type Params = PathParams[Path.CategorySetCreate];

const buildBreadcrumbsRoutes = (marketId: MarketId, intl: IntlShape) => {
    return [
        ...buildUpstreamBreadcrumbsRoutes(marketId, intl),
        {
            path: buildLink(Path.CategorySetCreate, {
                marketId
            }),
            breadcrumbName: intl.formatMessage(
                Messages['common.label.create-category-set']
            )
        }
    ];
};

export const CategorySetCreatePage = () => {
    const intl = useIntl();
    const categoriesData = useContext(CategoriesDataContext);
    const marketLanguagesData = useContext(MarketLanguagesDataContext);
    const params = useParams<Params>();
    const navigate = useNavigate();

    const { createCategorySet } = useProductCatalogService();

    const handleCategorySetCreateComplete = (
        completionParams: CompletionParams<
            unknown,
            ResultEnvelope<CreateCategorySetOutput, unknown>
        >
    ) => {
        if (completionParams.error) {
            void errorNotification({
                error: completionParams.error,
                message: intl.formatMessage(
                    Messages['common.error-message.category-set-create']
                )
            });

            return;
        }

        void message.success(
            intl.formatMessage(
                Messages['common.success-message.category-set-create']
            )
        );

        const detailsLink = buildLink(Path.CategorySetDetails, {
            marketId: params.marketId,
            id: completionParams.data?.data?.id as string
        });

        navigate(detailsLink);
    };

    const categorySetCreate = usePromise({
        variables: undefined as unknown as CreateCategorySetInput,
        promiseBuilder: async (variables) => {
            return createCategorySet(variables);
        },
        disableAutostart: true,
        onComplete: handleCategorySetCreateComplete
    });

    const categoriesLoading = categoriesData.loading && !categoriesData.data;
    const marketLanguagesLoading =
        marketLanguagesData.loading && !marketLanguagesData.data;

    const loading = categoriesLoading || marketLanguagesLoading;

    if (loading) {
        return <Loader type="center" />;
    }

    const categories = categoriesData.data;
    const marketLanguages = marketLanguagesData.data?.current;

    if (!categories || !marketLanguages) {
        return null;
    }

    const breadcrumbRoutes = buildBreadcrumbsRoutes(params.marketId, intl);

    const handleSubmit = (categorySet: CategorySetFormPayload) => {
        const { type, ...rest } = categorySet;

        if (type === undefined) {
            throw new Error('Category set type was not provided in the form');
        }

        const input = {
            ...rest,
            type
        };

        void categorySetCreate.execute(input);
    };

    return (
        <CategorySetFormLayout
            title={intl.formatMessage(
                Messages['common.label.create-category-set']
            )}
            categories={categories}
            breadcrumbRoutes={breadcrumbRoutes}
            marketLanguages={marketLanguages}
            submitting={categorySetCreate.loading}
            onSubmit={handleSubmit}
        />
    );
};
