import { Box } from '../components/Box';
import { MarketId, marketNameMap } from '../routing';

interface Props {
    markets: MarketId[];
    onMarketSelect?: (marketId: MarketId) => void;
}

export const SignInLayout = ({ markets, onMarketSelect }: Props) => {
    return (
        <Box display="flex" gridGap={10} data-testid="sign-in-layout">
            {markets.map((marketId) => (
                <button
                    key={marketId}
                    onClick={() => onMarketSelect?.(marketId)}
                >
                    {marketNameMap[marketId]}
                </button>
            ))}
        </Box>
    );
};
